import dayjs from "dayjs";

class Feriados {
  addDays;
  feriados;
  feriadosFiltrados;

  constructor(feriados, addDays) {
    this.feriados = feriados;
    this.addDays = addDays;
  }

  getDiaUtil(some, dia) {
    let nowDate = dia ? dayjs(dia) : dayjs();
    const now =
      this.addDays && some ? nowDate.add(this.addDays, "day") : nowDate;

    const date = {
      day: now.date(),
      month: now.month(),
      year: now.year(),
      dayWeek: now.get("day"),
    };

    this.feriadosFiltrados = this.feriados.map((item) => {
      let dates = [];
      const itemDate = dayjs(item.date);
      const itemMonth = itemDate.month() + 1;

      if (date.month + 1 === itemMonth || date.month + 2 === itemMonth) {
        dates.push(itemDate.date());
      }

      return dates;
    });

    this.feriadosFiltrados = this.feriadosFiltrados.flat();

    if (date.dayWeek === 0) {
      return this.getProximoFeriado(now.add(1, "day"));
    } else if (date.dayWeek === 6) {
      return this.getProximoFeriado(now.add(2, "day"));
    }

    return this.getProximoFeriado(now);
  }

  getProximoFeriado(day) {
    for (let i = 0; i < this.feriadosFiltrados.length + 1; i++) {
      const date = day.add(i, "day");

      if (
        !this.feriadosFiltrados.includes(date.date()) &&
        !this.feriadosFiltrados.includes(date.add(i, "day"))
      ) {
        return date.format("DD/MM/YYYY");
      }
    }
  }
}

export default Feriados;
