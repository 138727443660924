import Step from "@mui/material/Step";
import styled from "styled-components";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { StepConnector, stepConnectorClasses } from "@mui/material";

export const StyledStepperContainer = styled.div``;
export const StyledStep = styled(Step)``;
export const StyledStepLabel = styled(StepLabel)``;
export const StyledStepContent = styled(StepContent)``;

export const StyledStepperConnector = styled(StepConnector)(({ filledcolor }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 18,
    width: "100%",
    left: "-50%",
    zIndex: -1,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: filledcolor,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: filledcolor,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#727272",
    borderTopWidth: 2,
  },
}));

const StyledStepper = styled(Stepper)`
  ${({ filledcolor }) =>
    filledcolor &&
    `
    .MuiStepIcon-root {
      width: 38px;
      height: 38px;
    }
    .MuiStepLabel-label {
      color: #fff;
      margin-top: 10px !important;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      display: none;
      @media (min-width: 800px) {
        display: block;
      }
    }
    .MuiStepLabel-iconContainer {
      background-color: ${filledcolor};
      border-radius: 50%;
      margin-left: -2px;
    }
    .Mui-disabled {
      .MuiStepIcon-root {
        width: 34px;
        height: 34px;
      }
      .MuiStepLabel-iconContainer {
        background-color: #fff;
        border-radius: 50%;
        margin-left: 2px;
        .MuiStepIcon-text {
          fill: #fff;
        }
        svg {
          fill: linear-gradient(169deg, rgba(152,152,152,1) 0%, rgba(111,111,111,1) 100%);
        }
      }
    }
    .MuiStepIcon-text {
      font-size: 12px !important;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
    }
    .MuiStepIcon-root.Mui-active,
    .MuiStepIcon-root.Mui-completed {
      color: ${filledcolor};
      border: 
    }
    .MuiStepLabel-iconContainer {
        background-color: #fff;
    }
    .MuiStepLabel-label.Mui-active,
    .MuiStepLabel-label.Mui-completed {
      color: ${filledcolor};
    }
  `}
`;

export default StyledStepper;
