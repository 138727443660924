import { axiosPrivate } from "../../../config/axios";
import { pMinDelay } from "../../../utils/promise";

export const fetchCotacao = (values, delay) => {
  const response = axiosPrivate.put("/quotation", values);

  return pMinDelay(response, delay);
};

export const getComplementosByCep = (values, delay = 0) => {
  const response = axiosPrivate.post("/address/complements", values);

  return pMinDelay(response, delay);
};

export const getProdutosOpcionais = (id, codplano, delay = 0) => {
  const response = axiosPrivate.get(`/products/optionals/${id}/${codplano}`);

  return pMinDelay(response, delay);
};
