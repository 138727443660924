import styled from "styled-components";

export const ModalStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #171717;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  color: #1a1a1a;
  overflow-y: auto;

  @media (min-width: 1000px) {
    width: 800;
  }
  @media (max-width: 900px) {
    width: 700;
    img {
      display: none;
    }

    @media (max-width: 800px) {
      img {
        display: block;
      }
    }
  }

  @media (max-width: 350px) {
    img {
      display: none;
    }
  }
`;

export const DialogTitle = styled.div`
  position: relative;
`;

export const Image = styled.div`
  position: absolute;
  right: 0;

  img {
    width: 15px;
  }
`;

export const ConfirmButton = styled.div`
  margin-top: 20px;
`;
