import React, { useCallback, useEffect, useRef, useState } from "react";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Linkify from "react-linkify";

import * as Yup from "yup";
import * as Api from "./api";
import * as messages from "../../../shared/message";
import Form from "./form";

import { Drawer } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import DrawerCard from "../../../components/DrawerCard";
import { useComplement, useCondominio } from "../../../hooks/reducers";
import { CLIQUE_FORMULARIO } from "../../../shared/message";
import { isValidArray } from "../../../utils/array";
import {
  ContainerButtons,
  ContainerItem,
  ContainerTotal,
  ContentContainer,
  CustomLink,
  Divisor,
  Header,
  NameTitle,
  ServicosLabel,
  SomaAdicionaisContainer,
  SubHeader,
  Title,
  TotalSpan,
  TotalValor,
  Obrigatorios,
  DivisorObrigatorios,
} from "./styles";

const isMobile = window.innerWidth < 800;

function DisponibilidadeSteps({ complementos, id, ...props }) {
  const formRef = useRef(null);

  const [tipoImovel, setTipoImovel] = useState();
  const [loading, setLoading] = useState(false);
  const [opcionais, setOpcionais] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [opcionaisSelecionados, setOpcionaisSelecionados] = useState([]);
  const [quantidadeMesh, setQuantidadeMesh] = useState(1);
  const [infoSeguro, setInfoSeguro] = useState({});
  const [state, dispatch] = useComplement();
  const [condominio, action] = useCondominio();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });

  const handleInfoSeguro = (info) => {
    if (info?.materialImovel?.value !== "Alvenaria") {
      info.materialImovel.error = "O seguro cobre apenas imóveis construídos em alvenaria.";
    } else {
      info.materialImovel.error = null;
    }
    setInfoSeguro(info);
  };

  const handleChangeComplemento = useCallback(() => {
    if (isValidArray(complementos)) {
      const TYPES = {
        CASA: 3,
        BLOCO: 2,
        TORRE: 2,
        SOBRADO: 3,
        LOJA: 3,
        CONDOMINIO: 2,
        SALA: 3,
      };

      const comp = complementos.at();

      setTipoImovel(TYPES[comp.type]);
    } else {
      setTipoImovel(1);
    }
  }, [complementos]);

  const handleVerifyCloseDrawer = () => {
    if (!opcionais.length) {
      return setOpenDrawer(false);
    }
    return setOpenDrawer(true);
  };

  const renderDrawer = () => {
    const { produto, form } = props;

    const renderTitle = () => {
      return (
        <>
          <Header>Serviços Adicionais</Header>
          <SubHeader>
            Ótima notícia <NameTitle>{form.socialname?.toUpperCase?.()}</NameTitle>, temos disponibilidade da Banda
            Larga no seu endereço e agora você pode adicionar serviços incríveis ao seu carrinho:
          </SubHeader>
        </>
      );
    };

    const renderButtons = () => {
      const { produto } = props;

      return (
        <>
          <ContainerButtons>
            <CustomLink onClick={() => handleCloseDrawer(false, produto)}>CONTINUAR SEM ADICIONAIS</CustomLink>
            <CustomButton
              id="contratarOpcionais"
              disabled={loading}
              label="Contratar"
              onClick={() => {
                if (!infoSeguro.materialImovel?.error) handleCloseDrawer(true, produto);
                else
                  setSnackbar({
                    open: true,
                    message: messages.MATERIAL_SEGURO_INVALIDO,
                  });
              }}
            />
          </ContainerButtons>
        </>
      );
    };

    const renderItems = () => {
      return !!opcionaisSelecionados.length
        ? opcionaisSelecionados.map((item) => {
            const isMesh = item.descricao.includes("MESH");
            const valor = isMesh ? item.valor * quantidadeMesh : item.valor;

            return (
              <>
                <ContainerItem>
                  <Title>{item.descricao}</Title>
                  <Divisor />
                  <Title>R$ {valor.toFixed(2).replace?.(".", ",")}</Title>
                </ContainerItem>
              </>
            );
          })
        : null;
    };

    const renderTotal = () => {
      const totalOpcionais = opcionaisSelecionados.reduce((acc, item) => {
        const isMesh = item.descricao.includes("MESH");
        return isMesh ? acc + item.valor * quantidadeMesh : acc + item.valor;
      }, produto.precoProdutoComDesconto || produto.precoProduto);

      return (
        <ContainerTotal>
          <TotalSpan>Total: </TotalSpan>
          <Divisor />
          <TotalValor>R${totalOpcionais.toFixed(2).replace?.(".", ",")}</TotalValor>
        </ContainerTotal>
      );
    };

    const renderCards = () => {
      return opcionais?.map((item) => (
        <DrawerCard
          key={item.id}
          opcional={item}
          ativo={opcionaisSelecionados.map((opcional) => opcional.id).includes(item.id)}
          produto={produto}
          opcionaisSelecionados={opcionaisSelecionados}
          setOpcionaisSelecionados={setOpcionaisSelecionados}
          quantidadeMesh={quantidadeMesh}
          setQuantidadeMesh={setQuantidadeMesh}
          infoSeguro={infoSeguro}
          onChangeInfoSeguro={handleInfoSeguro}
        />
      ));
    };

    const Content = () => {
      return (
        <>
          <ContentContainer>
            {renderTitle()}
            {renderCards()}
          </ContentContainer>

          <SomaAdicionaisContainer>
            <ServicosLabel>Serviços no carrinho:</ServicosLabel>
            <ContainerItem>
              <Title>Plano {produto.download?.replace?.(/\D/g, "")}MB</Title>
              <Divisor />
              <Title>
                R${" "}
                {produto.precoProdutoComDesconto?.toFixed(2).replace?.(".", ",") ||
                  produto.precoProduto.toFixed(2).replace?.(".", ",")}
              </Title>
            </ContainerItem>
            {produto.servicosObrigatorios && produto.servicosObrigatorios.length > 0 && (
              <>
                <ContainerItem>
                  <Obrigatorios> {produto.servicosObrigatorios.map((i) => i.descricao).join(", ")}</Obrigatorios>
                  <DivisorObrigatorios />
                  <Title>R$ 0</Title>
                </ContainerItem>
              </>
            )}

            {renderItems()}
            {renderTotal()}
            {renderButtons()}
          </SomaAdicionaisContainer>
        </>
      );
    };

    return (
      <Drawer anchor={isMobile ? "bottom" : "right"} open={openDrawer} onClose={handleVerifyCloseDrawer}>
        <Content />
      </Drawer>
    );
  };

  const getProdutosOpcionais = useCallback(async () => {
    try {
      const { data } = await Api.getProdutosOpcionais(id, props.produto.id);

      setOpenDrawer(!!data?.length);

      setOpcionais(data);
    } catch (err) {
      setOpenDrawer(false);
    }
  }, [id, props.produto.id]);

  useEffect(() => {
    window.scrollTo(0, 0);

    handleChangeComplemento();

    getProdutosOpcionais();
  }, [handleChangeComplemento, getProdutosOpcionais]);

  const handleClose = () => {
    setSnackbar({ open: false });
  };

  const handleChangeResidencia = (e) => {
    const value = e.target.value;
    setTipoImovel(value);
  };

  const handleCloseDrawer = (accept) => {
    const total = opcionaisSelecionados.reduce((acc, item) => {
      const isMesh = item.descricao.includes("MESH");
      return isMesh ? acc + item.valor * quantidadeMesh : acc + item.valor;
    }, 0);

    try {
      window.dataLayer.push({
        event: "servicos_adicionais",
        page_location: "https://vendas.liggavc.com.br",
        currency: "BRL",
        value: total.toFixed(2) || 0,
        items: opcionaisSelecionados.map((item) => ({
          item_id: item.id,
          item_name: item.descricao.includes("MESH")
            ? `${item.descricao} - ${quantidadeMesh} unidade(s)`
            : item.descricao,
        })),
      });
    } catch (err) {}

    const newOpcionais = accept ? [...opcionaisSelecionados] : [];

    if (accept && !opcionaisSelecionados.length) {
      setSnackbar({
        open: true,
        message: "Você deve escolher os adicionais que deseja",
      });
    } else {
      setOpcionaisSelecionados(newOpcionais);
      setOpenDrawer(false);
    }
  };

  const messageLinked = (error) => (
    <Linkify>
      <span>{error.msg}</span>
      <br />
      {error.link && (
        <a href={error.link} style={{ color: "#b3d2ff" }} target="_blank" rel="noreferrer">
          {CLIQUE_FORMULARIO}
        </a>
      )}
    </Linkify>
  );

  const handleSubmit = async (data) => {
    const { form, handleCaptureData, handleNextStep, currentStep, setCredito, setDiasInstalacao, setViabilidade } =
      props;

    try {
      setLoading(true);

      const schema = Yup.object().shape({
        fullname: Yup.string().required("Campo obrigatório"),
        birthdate: Yup.date()
          .transform(function (_value, original) {
            dayjs.extend(customParseFormat);

            if (dayjs(original, "DD/MM/YYYY", true).isValid()) {
              const result = dayjs(original, "DD/MM/YYYY").format("YYYY-MM-DD");

              return new Date(result);
            }

            return "invalid";
          })
          .typeError("Insira uma data válida")
          .max(dayjs().subtract(18, "year"), "Você deve ser maior de 18 anos")
          .required("Campo Obrigatório"),
        cpf: Yup.string().required("Campo Obrigatório"),
        mother: Yup.string().required("Campo Obrigatório"),
        ...(!complementos?.length && tipoImovel === 3
          ? {
              nameCondominio: Yup.string().required("Campo Obrigatório"),
              qtdCasas: Yup.string().required("Campo Obrigatório"),
              complement: Yup.string().required("Campo Obrigatório"),
            }
          : {}),
      });

      await schema.validate(data, { abortEarly: false });

      if (tipoImovel === 2 && !state.id && complementos.length) {
        return setSnackbar({
          open: true,
          message: "Você deve selecionar uma unidade",
        });
      }

      if (tipoImovel === 2 && !state.unidade && complementos.length) {
        return setSnackbar({
          open: true,
          message: "Você deve selecionar um andar",
        });
      }

      if (tipoImovel === 2 && !state.numero && complementos.length) {
        return setSnackbar({
          open: true,
          message: "Você deve selecionar um número",
        });
      }

      if (tipoImovel === 3 && !condominio.id && complementos.length) {
        return setSnackbar({
          open: true,
          message: "Você deve selecionar uma unidade",
        });
      }

      const dados = {
        cep: form.endereco?.cep,
        birthdate: dayjs(data.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        address: form.endereco.logradouro,
        number: form.endereco.number,
        fullname: data.fullname,
        mother: data.mother,
        cpf: data.cpf.replace(/\D/g, ""),
        email: form.email,
        complement: data.complement,
        type: tipoImovel,
        codplano: Number(props.idProduto),
        plano: props.produto,
        opcionais: opcionaisSelecionados.map((item) => ({
          ...item,
          quantidade: item.descricao.includes("MESH") ? quantidadeMesh : 1,
          tipoMaterial: infoSeguro?.materialImovel?.value,
          confirmaInformacoesVerdadeiras: infoSeguro?.confirmaInfoVerdadeira?.value,
          concordaComTermos: infoSeguro?.aceitaTermosSeguro?.value,
        })),
        condominioComplemento: condominio.unidade || state.name || "",
        condominioType: condominio.name || state.type || "",
        buildingTower: state.id || condominio.id || "",
        floor: state.unidade || "",
        unit: state.numero || "",
        ...(!complementos?.length &&
          tipoImovel === 3 && {
            nameCondominio: data.nameCondominio || "",
            qtdCasas: data.qtdCasas || "",
          }),
        id,
      };

      try {
        window.dataLayer.push({
          event: "disponibilidade",
          page_location: "https://vendas.liggavc.com.br",
          currency: "BRL",
          value: props?.produto?.precoProduto,
          items: [
            {
              item_id: Number(props?.idProduto),
              item_name: props?.produto?.nomeProduto,
            },
          ],
        });
      } catch (err) {}

      handleCaptureData({ ...dados, tipoImovel });

      const { data: res } = await Api.fetchCotacao(dados);

      if (res?.result) {
        const { result, daysOffset } = res;

        setCredito(result);
        setDiasInstalacao(daysOffset);
      }

      handleNextStep({ activeStep: currentStep + 1 });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const { ...errors } = err;
        const errorMessages = {};

        errors.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      } else {
        const {
          response: { data: res },
        } = err;

        if (res.errors) {
          const { errors } = res;
          let errorsField = {};

          errors.forEach((error) => {
            if (error.field === "snack") {
              return setSnackbar({
                open: true,
                message: messageLinked(error),
              });
            } else {
              errorsField[error.field] = error.msg;
            }
          });
          return formRef.current.setErrors(errorsField);
        }

        if (!res.hasAvailability) {
          setViabilidade(res.hasAvailability);
          handleNextStep({ activeStep: 1 });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form
        {...props}
        complementos={complementos}
        complementReducer={[state, dispatch]}
        action={action}
        formRef={formRef}
        handleClose={handleClose}
        handleChangeResidencia={handleChangeResidencia}
        handleSubmit={handleSubmit}
        loading={loading}
        opcionais={opcionais}
        renderDrawer={renderDrawer}
        snackbar={snackbar}
        tipoImovel={tipoImovel}
      />
    </>
  );
}

export default DisponibilidadeSteps;
