import styled from "styled-components";

export const ButtonStoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 100px;
  @media (max-width: 500px) {
    margin-top: 70px;
    margin-left: 15px;
  }
  @media (min-width: 500px) and (max-width: 800px) {
    
  }
`;

export const ButtonStore = styled.div`
  margin-right: 5px;
`;

export const CheckboxContainer = styled.div`
  margin: 20px auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: self-start;
  padding: 10px 40px 10px 160px;
`;

export const Image = styled.div`
  display: flex;
  z-index: 10;
  left: -30px;
  top: -20px;
  height: 300px;
  width: 300px;
  position: absolute;
  
  @media (max-width: 800px) {
    width: 100%;
  }
  
  @media (max-width: 500px) {
    left: 15px;
  }
  
  @media (min-width: 500px) and (max-width: 800px) {
    left: 20px;
  }
  `;

export const FooterContent = styled.span`
  color: #fff;
  text-align: center;
  font-weight: bold;
  margin: 20px 0px;
  line-height: 20px;
  font-size: 17px;
  @media (max-width: 500px) {
    text-align: left;
    margin-left: 30px;
    font-size: 15px;
  }
  @media (min-width: 500px) and (max-width: 800px) {
    margin-left: 30px;
  }
  `;

export const Header = styled.span`
  color: #fff;
  font-size: 24px;
  font-weight: bold;
`;

export const ProtocoloContainer = styled.div`
  background: #ff6f00;
  border-radius: 15px;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Protocolo = styled.div`
  color: #ffff;
  margin-left: 24px;
  font-size: 25px;
  font-weight: bold;
`;

export const StoreButton = styled.div`
  border: 2px solid #e0e0e0;
  width: 160px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
  user-select: none;
  cursor: pointer;

  :hover {
    transition: box-shadow 0.3s;
    box-shadow: 0 0 0.5rem #ff6f00;
  }
`;

export const SubTitle = styled.span`
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin: 20px 0px;
`;

export const TextStoreButton = styled.span`
  color: #727272;
  font-weight: bold;
  margin-left: 15px;
`;
