import { FormControl, FormHelperText } from "@mui/material";
import { CustomSelect } from "./styles";

export default function XSelect({ id, children, error, ...props }) {
  return (
    <FormControl fullWidth error={!!error}>
      <CustomSelect {...props} id={id || "XSelect"}>
        {children}
      </CustomSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
