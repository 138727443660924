import React, { useEffect, useRef, useState } from "react";
import Form from "./form";
import * as Api from "./api";
import * as Yup from "yup";

function RevisaoSteps({ pagamento, ...props }) {
  const formRef = useRef(null);

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [alterarDados, setAlterarDados] = useState(false);
  const [alterarLoading, setAlterarLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [produtos, setProdutos] = useState([]);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });

  useEffect(() => window.scrollTo(0, 0), []);

  const openSnackbar = (message) => setSnackbar({ open: true, message });

  const closeSnackbar = () => {
    setSnackbar({ open: false });
  };

  const handleAlterar = async () => {
    const { handleCaptureData, id } = props;

    const dados = formRef.current.getData();
    const phonenumber = Number(dados.phonenumber?.replace(/\D/g, ""));

    const fields = {
      phonenumber: phonenumber || "",
      email: dados.email,
    };

    try {
      setAlterarLoading(true);

      const schema = Yup.object().shape({
        phonenumber: Yup.string().min(11, "Insira um número válido").required("Campo Obrigatório"),
        email: Yup.string().email("Insira um e-mail válido").required("Campo Obrigatório"),
      });

      await schema.validate(fields, { abortEarly: false });

      await Api.changeContacts({ id, ...fields });

      handleCaptureData(fields);

      setAlterarDados(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const { ...errors } = err;
        const errorMessages = {};

        errors.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      } else {
        const {
          response: { data: res },
        } = err;
        openSnackbar(res.msg);
      }
    } finally {
      setError(false);
      setAlterarLoading(false);
    }
  };

  const handleSubmit = async () => {
    const { id, setProtocolo } = props;

    try {
      setLoading(true);

      if (!acceptTerms) {
        setError(true);
        openSnackbar("É necessário aceitar os termos");
        return;
      } else {
        setError(false);
      }

      const { data: response } = await Api.saveData({ id, acceptTerms });

      setProtocolo(response.protocol);
    } catch (err) {
      const {
        response: { data: res },
      } = err;
      openSnackbar(res.msg);
    } finally {
      setLoading(false);
    }

    try {
      window.dataLayer.push({
        event: "purchase_form",
        page_location: "https://vendas.liggavc.com.br",
        transaction_id: id,
        currency: "BRL",
        value: props?.produto?.precoProduto,
        items: [
          {
            item_id: Number(props?.idProduto),
            item_name: props?.produto?.nomeProduto,
          },
        ],
      });
    } catch (err) {}
  };

  return (
    <Form
      {...props}
      acceptTerms={[acceptTerms, setAcceptTerms]}
      alterarDados={[alterarDados, setAlterarDados]}
      alterarLoading={alterarLoading}
      error={error}
      formRef={formRef}
      handleAlterar={handleAlterar}
      handleSubmit={handleSubmit}
      loading={loading}
      snackbar={[snackbar, closeSnackbar]}
      produtos={produtos}
      pagamento={pagamento}
    />
  );
}

export default RevisaoSteps;
