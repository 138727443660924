import styled from "styled-components";

export const ContentContainer = styled.div`
  padding: 70px 60px;
  transition: all 0.3s ease-in-out;
`;

export const ContainerItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const CustomLink = styled.div`
  color: #d1d1d1;
  font-weight: bold;
  border-bottom: 1px solid #d1d1d1;
  cursor: pointer;
  font-size: 12px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
`;

export const Divisor = styled.div`
  border: 1px solid #d1d1d1;
  width: 60%;
  max-width: 70%;
  margin: 0px 5px;
  height: 0;
`;

export const DivisorObrigatorios = styled.div`
  border: 1px solid #d1d1d1;
  width: 50%;
  margin: 0px 5px;
`;

export const Header = styled.div`
  color: #ff6f00;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    font-size: 32px;
  }
`;

export const NameTitle = styled.span`
  color: #ff6f00;
  font-weight: bold;
`;

export const ServicosLabel = styled.div`
  color: #727272;
  font-size: 18px;
  display: flex;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  margin-bottom: 40px;
`;

export const SubHeader = styled.div`
  color: #727272;
  width: 600px;
`;

export const Title = styled.div`
  width: fit-content;
  font-weight: bold;
  color: #fff;
`;

export const Obrigatorios = styled.div`
  width: fit-content;
  font-weight: 300;
  color: #fff;
`;

export const TotalSpan = styled.span`
  font-weight: bold;
  color: #fff;
`;

export const TotalValor = styled.span`
  font-size: 25px;
  font-weight: bold;
  color: #fff;
`;

export const SomaAdicionaisContainer = styled.div`
  background: rgb(234, 91, 12);
  background: linear-gradient(
    166deg,
    rgba(234, 91, 12, 1) 0%,
    rgba(228, 0, 0, 1) 100%
  );
  height: 500px;
  padding: 60px;
`;
