import styled from "styled-components";

import { Radio } from "@mui/material";

export const XRadio = styled(Radio)`
  &.MuiRadio-root {
      color: #4d4d4d;
      transition: ease .5s;
    &.Mui-checked {
      color: #ff6f00;
    }
    :hover {
      color: #FFB67D;
    }
  }
`;
