import React from "react";
import Grid from "@mui/material/Grid";

import {
  Banner,
  ColorizedText,
  ContainerProduto,
  DescritionContainer,
  Item,
  ListItem,
  Paper,
  Price,
  SvaContainer,
  SvaImage,
  SvaImageContainer,
  SvaName,
  TaxaInstalacao,
  Text,
  TitleContainer,
  TitleSva,
  TotalPrice,
  TotalPriceContainer,
  TotalPriceLabel,
  TotalPriceLabelBold,
  TotalPriceLabelContainer,
  TotalPriceLabelInerit,
  WeightText,
} from "./styles";

import IMAGINA_SO from "../../assets/icons/ImaginaSo.jpeg";
import MOLTI from "../../assets/icons/Molti.jpg";
import MOLTI_EDUCACIONAL from "../../assets/icons/MoltiEducation.jpg";
import MUMO from "../../assets/icons/Mumo.png";
import REVISTA_JA from "../../assets/icons/RevistaJa.png";
import SKEELO from "../../assets/icons/Skeelo.png";
import SKEELO_AUDIOBOOK from "../../assets/icons/Skeelo_Audiobook.png";
import SUPER_COMICS from "../../assets/icons/SuperComics.png";
import WATCH_TV from "../../assets/icons/WatchTv.png";

const SVA = {
  AUDIO_BOOK: SKEELO_AUDIOBOOK,
  MUMO,
  SKEELO,
  IMAGINA_SO,
  WATCH_TV,
  MOLTI_EDUCACIONAL,
  MOLTI,
  REVISTA_JA,
  SUPER_COMICS,
};

export default function ProductBanner({ produto, taxaInstalacao, opcionais, pagamento }) {
  const { parcelamentosSemFidelidade } = produto;

  const renderPrice = () => {
    const produtoPrice =
      pagamento === "boleto" ? produto.precoProduto : produto.precoProdutoComDesconto || produto.precoProduto;
    const fixedPrice = produtoPrice.toFixed(2);

    return fixedPrice.replace(".", ",");
  };

  const renderPriceTotal = () => {
    if (opcionais.length) {
      return opcionais
        .reduce(
          (acc, item) => acc + item.valor * item.quantidade,

          pagamento === "boleto" ? produto.precoProduto : produto.precoProdutoComDesconto || produto.precoProduto
        )
        .toFixed?.(2)
        .replace?.(".", ",");
    }

    return pagamento === "boleto" ? produto.precoProduto : produto.precoProdutoComDesconto || produto.precoProduto;
  };

  return (
    <ContainerProduto>
      <Paper>
        <Banner>
          <TitleContainer>
            <Text color="#ffff">
              {produto.promocao}
              <br />
              {produto.nomeProduto}
              <br />
              por R$
              <Price>{renderPrice()}</Price>
              /mês
              <br />
            </Text>
          </TitleContainer>
        </Banner>

        <DescritionContainer>
          <Grid container spacing={2} style={{ margin: "3px" }}>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6} style={{ margin: "0 0 20px 0" }}>
              <ListItem>
                <Item>
                  - Download de até <ColorizedText color="#ff6f00">{produto.download}</ColorizedText>
                </Item>
                <Item>
                  - Upload de até <ColorizedText color="#ff6f00">{produto.upload}</ColorizedText>
                </Item>
                <Item>- Wi-Fi Grátis</Item>
                <Item>- 100% Fibra Óptica</Item>
                {produto.servicosObrigatorios && produto.servicosObrigatorios.length > 0 && (
                  <Item>- {produto.servicosObrigatorios.map((i) => i.descricao).join(", ")}</Item>
                )}
              </ListItem>
              {taxaInstalacao ? (
                <TaxaInstalacao>
                  Taxa de Instalação: R$
                  {parcelamentosSemFidelidade?.valorTotal?.toFixed(2).replace?.(".", ",")}
                </TaxaInstalacao>
              ) : null}
            </Grid>
            <>
              <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                <TitleSva>
                  <WeightText>Serviços Digitais</WeightText>
                </TitleSva>
                <SvaContainer>
                  {produto.sva?.map((item) => (
                    <SvaImageContainer>
                      <SvaImage src={SVA[item.name] || ""} alt={item.name} />
                      <SvaName>{item.name?.replace("_", " ")}</SvaName>
                    </SvaImageContainer>
                  ))}
                </SvaContainer>
              </Grid>
            </>
          </Grid>

          <>
            <TitleSva style={{ margin: "10px 0 0 0" }}>
              <WeightText style={{ fontSize: "18px", color: "#ED8836" }}>Produtos Adicionais</WeightText>
            </TitleSva>
            <ul style={{ color: "#fff" }}>
              {opcionais?.length ? (
                opcionais.map((item) => (
                  <li>
                    - {item.quantidade} {item.descricao}
                  </li>
                ))
              ) : (
                <li>- Sem opcionais selecionados</li>
              )}
            </ul>
          </>
        </DescritionContainer>
        {opcionais?.length ? (
          <TotalPriceContainer>
            <TotalPrice>
              <TotalPriceLabelContainer>
                <TotalPriceLabel>Total:</TotalPriceLabel>
                <TotalPriceLabelInerit>
                  R$ <TotalPriceLabelBold>{renderPriceTotal()} </TotalPriceLabelBold>
                  /mês
                </TotalPriceLabelInerit>
              </TotalPriceLabelContainer>
            </TotalPrice>
          </TotalPriceContainer>
        ) : null}
      </Paper>
    </ContainerProduto>
  );
}
