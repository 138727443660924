import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 30px;
  border: 1px solid #e0e0e0;
  text-align: center;
`;

export const Label = styled.p`
  color: #727272;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.3;
`;

export const ButtonGroup = styled.div`
  margin: 0 auto;
`;

export const CustomButton = styled.div`
  display: flex;
  justify-content: center;
  background-color: #faa61a;
  border-radius: 60px;
  color: #ffff;
  font-size: 15px;
  align-items: center;
  font-weight: bold;
  padding: 16px 23px;
  border: 1px solid;
  margin-top: ${(props) => props.gutterTop};
  margin-bottom: ${(props) => props.gutterBottom};
  width: 200px;
  cursor: pointer;

  &:active {
    background-color: #ffb12e;
    transition: background-color 0.1s;
  }

  &:hover {
    transition: ease-in-out 0.3s;
    box-shadow: 0 0 0.4rem #faa61a;
  }
`;
