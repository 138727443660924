import React, { useEffect } from "react";
import Form from "./form";

function ConclusaoSteps({ ...props }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Form {...props} />
    </>
  );
}

export default ConclusaoSteps;
