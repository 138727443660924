import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import {
  ButtonContainer,
  CardFatura,
  Descrition,
  Info,
  Label,
  Title,
  XButton,
} from "../../containers/Steps/Faturamento/styles";
import XRadioGroup from "../CustomRadio";
import { FormControlLabel, Grid, InputLabel, MenuItem, RadioGroup } from "@mui/material";
import XSelect from "../Select";
import { CustomInputSelect } from "../Select/styles";
import GridItem from "../GridItem";
import GridContainer from "../GridContainer";
import XInput from "../Form/input";

import { ModalStyle, Image, DialogTitle, ConfirmButton } from "./styles";
import CustomButton from "../CustomButton";

import CloseIcon from "../../assets/icons/close.svg";

const styles = {
  buttonSubsmit: { display: "flex", justifyContent: "flex-end" },
  chip: { marginLeft: 0, marginRight: 0 },
  diaFatura: { margin: "18px 0px" },
  diaInstalacao: {
    marginTop: "10px",
    fontWeight: "bold",
    fontSize: "16px",
  },
  snackbar: {
    anchor: {
      vertical: "bottom",
      horizontal: "center",
    },
    style: {
      ".MuiSnackbarContent-root": {
        background: "#E53935",
        color: "#fff",
        fontSize: "16px",
      },
    },
  },
  tipoFatura: {
    marginTop: "10px",
  },
  tipoFaturaComponent: { marginLeft: 0, marginRight: 0 },
  confirmabutton: { marginTop: "20px" },
  modalScroll: { overflowY: "auto" },
};

export default function BasicModal({ loading, produto, form, formRef, ...props }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeFidelidade = (value) => {
    const isTrue = value === "V";

    setFilidade(value);
    setHaveTaxa(!isTrue);
  };

  const [fidelidade, setFilidade] = props.fidelidade;
  // eslint-disable-next-line no-unused-vars
  const [haveTaxa, setHaveTaxa] = props.haveTaxa;
  const [numParcelas, setNumParcelas] = props.parcela;

  const [tipoFatura, setTipoFatura] = props.tipoFatura;
  const [pagamento, setTipoPagamento] = props.tipoPagamento;
  const [fatura, setFatura] = props.fatura;

  const renderFidelidade = () => {
    const { parcelamentosSemFidelidade, parcelamentosComFidelidade } = produto;
    const parcelamentoProduto = {
      V: parcelamentosComFidelidade,
      F: parcelamentosSemFidelidade,
    };
    const parcelamento = parcelamentoProduto[fidelidade];

    const taxaInstalacao = Number(parcelamentoProduto["F"]?.valorTotal).toFixed?.(2).replace?.(".", ",");
    return (
      <>
        <Box sx={{ marginTop: "30px" }} display="grid" gridTemplateColumns="1fr" gap={1}>
          <Info bold>Fidelidade</Info>
        </Box>

        <XRadioGroup
          name="fidelidade"
          onChange={handleChangeFidelidade}
          defaultValue="V"
          items={[
            { value: "V", label: "Com Fidelidade (Sem custo)" },
            {
              value: "F",
              label: `Sem Fidelidade (R$${taxaInstalacao})`,
            },
          ]}
        />

        {parcelamento && parcelamento?.parcelas?.length ? (
          <Grid item xs={6} md={6} sm={6} lg={6} sx={{ marginTop: "15px" }}>
            <InputLabel shrink sx={(styles.diaInstalacao, { color: "#fff" })} htmlFor="numParcelas" required>
              Parcelamento
            </InputLabel>
            <XSelect
              name="numParcelas"
              disabled={loading}
              input={<CustomInputSelect />}
              defaultValue={numParcelas}
              onChange={(e) => setNumParcelas(e.target.value)}
            >
              {parcelamento?.parcelas?.map((item) => {
                return (
                  <MenuItem value={item.quantidade}>
                    {item.quantidade} x {Number(item.valor).toFixed(2).replace?.(".", ",")}
                  </MenuItem>
                );
              })}
            </XSelect>
          </Grid>
        ) : null}
      </>
    );
  };

  const initialData = {
    cepbilling: form.endereco?.cep,
    addressbilling: form.endereco.logradouro,
    numberbilling: form.endereco.number,
    complementbilling: form.complement,
  };

  const handleChangeTipoFatura = (value) => {
    setTipoFatura(value);
    setFatura(value);
  };

  const setCurrentTipoFatura = (e) => {
    const value = e.target.value;

    setTipoFatura(value);

    if (value === 1) {
      formRef.current.setData(initialData);
    } else {
      formRef.current.reset();
    }
  };

  const TipoFatura = ({ title, label, ativo, callback, value }) => {
    return (
      <GridItem xs={12} md={12} xl={12} sm={12} lg={12}>
        <CardFatura className={ativo && "ativo"}>
          <Descrition className="title">
            <Title>{title}</Title>
            <Label>{label}</Label>
          </Descrition>
          <ButtonContainer>
            <XButton active={ativo} onClick={() => callback(value)}>
              {ativo ? "SELECIONADO" : "SELECIONAR"}
            </XButton>
          </ButtonContainer>
        </CardFatura>
      </GridItem>
    );
  };

  return (
    <div>
      <Typography sx={{ fontSize: "12px" }}>
        Esta oferta contempla fidelidade, débito em conta e fatura digital. Caso queira alterar
        <Button
          onClick={handleOpen}
          sx={{
            color: "#FF5F1E",
            textTransform: "lowercase",
            padding: "0 0 0 5px",
          }}
        >
          {" "}
          clique aqui.
        </Button>
      </Typography>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={styles.modalScroll}
      >
        <ModalStyle>
          <DialogTitle>
            <Image>
              <img src={CloseIcon} alt="" onClick={handleClose} />
            </Image>
          </DialogTitle>

          <GridItem xs={12} md={12} sm={12} lg={12} sx={{ color: "#fff" }}>
            {renderFidelidade()}
          </GridItem>

          <Box sx={{ marginTop: "30px", color: "#fff" }} display="grid" gridTemplateColumns="1fr" gap={1}>
            <Info bold>Forma de pagamento</Info>
            <XRadioGroup
              name="metodoPagamento"
              onChange={setTipoPagamento}
              defaultValue={pagamento}
              items={[
                { value: "debito", label: "Débito em Conta" },
                { value: "boleto", label: "Boleto" },
              ]}
            />
          </Box>

          <GridItem item xs={12} md={12} sm={12} lg={12} sx={{ marginTop: "20px" }}>
            <Info bold>Como deseja receber sua fatura?</Info>
            <RadioGroup defaultValue="digital">
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                <FormControlLabel
                  style={styles.tipoFaturaComponent}
                  value={0}
                  control={
                    <TipoFatura
                      title="Digital"
                      name="digital"
                      label="Receba sua fatura por e-mail"
                      ativo={fatura === 0}
                      callback={handleChangeTipoFatura}
                    />
                  }
                />
                <FormControlLabel
                  value={1}
                  style={styles.tipoFaturaComponent}
                  control={
                    <TipoFatura
                      title="Impresso"
                      name="impresso"
                      label="Receba sua fatura impressa"
                      ativo={fatura === 1}
                      callback={handleChangeTipoFatura}
                    />
                  }
                />
              </Box>
            </RadioGroup>
          </GridItem>

          {fatura === 1 && (
            <GridContainer direction="row" columns={{ xs: 12, sm: 12, md: 12, xl: 12 }}>
              <GridItem md={12} lg={12} xs={12} sm={12}>
                <XSelect
                  id="tipoFatura"
                  sx={styles.tipoFatura}
                  onChange={setCurrentTipoFatura}
                  input={<CustomInputSelect />}
                  defaultValue={1}
                >
                  <MenuItem value={0}>Outro endereço de Cobrança</MenuItem>
                  <MenuItem value={1}>Mesmo endereço de Cobrança</MenuItem>
                </XSelect>
              </GridItem>
              <GridItem lg={2} xl={2} md={6} xs={12}>
                <XInput
                  length={8}
                  name="cepbilling"
                  disabled={tipoFatura === 1}
                  title="CEP"
                  required
                  className="cobrancaboleto"
                />
              </GridItem>
              <GridItem lg={4} xl={4} md={6} xs={12}>
                <XInput
                  name="addressbilling"
                  length={90}
                  disabled={tipoFatura === 1}
                  title="Endereço da Cobrança"
                  required
                  className="cobrancaboleto"
                />
              </GridItem>
              <GridItem lg={2} xl={2} md={6} xs={12}>
                <XInput
                  length={5}
                  name="numberbilling"
                  disabled={tipoFatura === 1}
                  title="Número"
                  required
                  className="cobrancaboleto"
                />
              </GridItem>
              <GridItem lg={4} xl={4} md={6} xs={12}>
                <XInput
                  length={90}
                  name="complementbilling"
                  disabled={tipoFatura === 1}
                  title="Complemento"
                  className="cobrancaboleto"
                />
              </GridItem>
            </GridContainer>
          )}

          <ConfirmButton>
            <CustomButton
              id=""
              type="submit"
              disabled={loading}
              loading={loading}
              label="Confirmar alterações"
              onClick={handleClose}
            />
          </ConfirmButton>
        </ModalStyle>
      </Modal>
    </div>
  );
}
