import styled from "styled-components";

export const AlterarButton = styled.div`
  color: #faa61a;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #faa61a;
  text-align: center;
`;

export const CheckboxContent = styled.div`
  margin: 15px 0px 5px 0px;
`;

export const CheckboxContentSeguro = styled.div`
  margin: 5px 0 0 0;
`;

export const DadosInstalacaoContainer = styled.div`
  border: 2px solid #e0e0e0;
  border-radius: 5px;
`;

export const DadosInstalacaoInfos = styled.div`
  margin: 20px;
`;

export const DadosPessoaisContainer = styled.div`
  border: 2px solid #e0e0e0;
  padding: 20px;
  border-radius: 5px;
`;

export const DestaqueTitle = styled.span`
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  font-size: ${({ size }) => `${size}px` || "16px"};
  ${({ top }) => top && `margin-top: ${top}px`}
`;

export const FidelidadeContainer = styled.div`
  border: 2px solid #e0e0e0;
  padding: 20px;
  border-radius: 5px;
`;

export const FidelidadeContent = styled.span`
  color: #fff;
  font-weight: bold;
`;

export const Divisor = styled.div`
  margin: 20px auto 20px auto;
  border: 1px solid #e0e0e0;
`;

export const InformacoesFaturaContainer = styled.div`
  border: 2px solid #e0e0e0;
  padding: 20px;
  border-radius: 5px;
`;

export const LinkLabel = styled.a`
  margin-left: 8;
  color: #cacaca;
  font-size: 15px;
  line-height: 146.9%;
`;

export const SpanLabel = styled.span`
  margin-left: 8;
  color: ${({ error }) => (error ? "#f00" : "#cacaca")};
  font-size: 15px;
  line-height: 146.9%;
`;

export const SpanLinkSaibaMais = styled.span`
  color: #cacaca;
  font-size: 12px;
`;
export const LinkSaibaMais = styled.a`
  font-size: 12px;
  color: #faa61a;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 25px;
  color: #ff6f00;

  ${({ top }) => top && `margin-top: ${top}px`}
`;

export const XDisplayTitle = styled.span`
  color: #fff;
  font-weight: bold;
`;

export const XDisplayLabel = styled.span`
  color: #cacaca;
  font-size: 16px;
`;
