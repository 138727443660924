import styled from "styled-components";

export const ButtonContainer = styled.div`
  margin: 20px 10px;
`;

export const CardFatura = styled.div`
  border: 2px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-radius: 10px;
  margin: 10px 0px;
  line-height: 30px;

  background: linear-gradient(to left, #fff 50%, #f26522 50%) right;
  background-size: 200% 100%;
  transition: 0.4s ease-out;

  @media (max-width: 900px) {
    display: block;
    width: 100%;

    .button {
      width: 100%;
    }
  }

  &.ativo {
    background-position: left;
    color: #fff;
  }
`;

export const Textoapoio = styled.label`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
`;

export const Chip = styled.div`
  height: 15px;
  width: 60px;
  padding: 20px;
  margin: 5px 5px;
  background-color: "#fff";
  color: ${(props) => (props.error ? "#f00" : "#fff")};
  border: 2px solid ${(props) => (props.error ? "#f00" : "#cccccc")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  transition: backgroud-color 0.5s, color 0.5s;

  &.ativo {
    background: rgb(101, 174, 84);
    background: linear-gradient(180deg, rgba(101, 174, 84, 1) 0%, rgba(177, 193, 77, 1) 100%);
    color: #fff;
  }
`;

export const Descrition = styled.div`
  margin: 20px 10px;
`;

export const ErrorFatura = styled.p`
  color: #f00;
  margin-top: 15px;
`;

export const Paragraph = styled.p`
  font-weight: bold;
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
  margin: ${({ gutter }) => gutter || "0px"};
`;

export const Info = styled.span`
  color: ${({ color }) => color || "#fff"};
  font-size: ${({ size }) => size || "18px"};
  margin: ${({ gutter }) => gutter || "0px"};

  ${({ bold }) =>
    bold &&
    `
    font-weight: bold;
    `}
`;

export const Label = styled.p`
  font-size: 14px;
  line-height: 18px;
`;

export const Title = styled.p`
  font-size: 22px;
  font-weight: bold;
`;

export const XButton = styled.div`
  border: 3px solid #faa61a;
  width: fit-content;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #faa61a;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  opacity: ${(props) => (props.active ? "80%" : "100%")};
`;
