import React from "react";

const styles = {
    button: { 
        marginBottom: '15px',
        border: '1px solid white',
        padding: '5px',
        backgroundColor: 'transparent',
        color: 'white',
        fontSize: '12px'
    },

}

export default function BackButton({onClickFn = () => {} }) { 
    const goBack = () => {
        if (onClickFn) onClickFn();
    }
    return (
        <>
            <button style={styles.button} onClick={goBack}>Voltar</button>	
        </>
    );
  }
    
