import React, { useEffect, useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import XTextField from "../Form/XTextField";

export default function GoogleAutoComplete({ disabled, name, required, setRua, title, value }) {
  const [valor, setValor] = useState({ label: "", id: null });

  useEffect(() => {
    if (value?.length === 1) {
      setValor({
        label: value.at()?.streetName,
        id: value.at()?.codLogradouro,
      });
    } else {
      setValor({
        label: "",
        id: null,
      });
    }
  }, [value]);

  const getPlaceholder = () => {
    if (value.length > 1) {
      return "Selecione sua rua";
    }

    if (disabled) {
      return "Pesquisando...";
    }

    return "";
  };

  const handleChangeAutoComplete = (_event, newValue, action) => {
    if (action === "clear") {
      setValor("");
    } else {
      setValor({ label: newValue?.label, id: newValue?.codLogradouro });
    }
  };

  const handleChangeTextField = (e) => {
    const actualValue = e.target.value?.toUpperCase();

    setValor({ label: actualValue, id: null });
  };

  const handleOnBlur = () => {
    if (valor?.label) {
      setValor({ label: valor, id: null });
      setRua([{ streetName: valor }]);
    }
  };

  const options = () => {
    if (Array.isArray(value) && value.length) {
      return value.map((item) => ({
        label: item.streetName,
        id: item.codLogradouro,
      }));
    }

    return [];
  };

  return (
    <Autocomplete
      disablePortal
      disabled={disabled || value.length === 1}
      id={name}
      value={valor?.label}
      loading={disabled}
      onChange={handleChangeAutoComplete}
      options={options()}
      renderInput={(params) => (
        <XTextField
          name={name}
          placeholder={getPlaceholder()}
          onChange={handleChangeTextField}
          onBlur={handleOnBlur}
          required={required}
          value={valor?.label}
          title={title}
          {...params}
        />
      )}
    />
  );
}
