import React, { useEffect, useRef, useState } from "react";
import Form from "./form";

import * as Api from "./api";
import * as Yup from "yup";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useFaturaValidation } from "../../../hooks/reducers";
import Feriados from "../../../utils/Feriados";

function FaturamentoSteps({ currentStep, form, handleNextStep, produto, pagamento, setPagamento, ...props }) {
  const formRef = useRef(null);
  let FeriadosClass;

  const [banks, setBanks] = useState([]);
  const [banco, setBanco] = useState("");
  const [dataInstalacao, setDataInstalacao] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [feriados, setFeriados] = useState([]);
  const [fatura, setFatura] = useState(0);
  const [verifyDataInstalacao, setVerifyDataInstalacao] = useState("V");
  const [days, setDays] = useState([]);
  const [tipoFatura, setTipoFatura] = useState(1);
  const [dataVencimento, setDataVencimento] = useState(null);
  const [fidelidade, setFilidade] = useState("V");
  const [numParcelas, setNumParcelas] = useState(1);
  const [haveTaxa, setHaveTaxa] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });

  const [state, dispatch] = useFaturaValidation();

  useEffect(() => {
    (async function () {
      try {
        setLoadingState(true);

        const { data: dias } = await Api.fetchDays();
        const { data: dates } = await Api.getFeriados(form.endereco.cep);
        const { data: bancos } = await Api.getBanks();

        await setDays(dias.days);
        await setFeriados(dates.holidays);
        await setBanks(bancos);
      } catch (err) {
        return setSnackbar({
          open: true,
          message: "Conexão com o servidor não estabelecida",
        });
      } finally {
        setLoadingState(false);
      }
    })();
  }, [form.endereco.cep]);

  FeriadosClass = new Feriados(feriados, props.diasInstalacao);

  const handleSubmit = async (data) => {
    const { idProduto } = props;

    dayjs.extend(customParseFormat);

    const { id, handleCaptureData } = props;

    const date = dataInstalacao ? dataInstalacao : FeriadosClass.getDiaUtil(true);
    const haveFidelidade = fidelidade === "V";

    try {
      setLoading(true);

      const schemaBank = Yup.object().shape({
        agenciaBancaria: Yup.string().required("Campo Obrigatório"),
        contaBancaria: Yup.string().required("Campo Obrigatório"),
        digitoConta: Yup.string().required("Campo Obrigatório"),
      });

      const schema = Yup.object().shape({
        cepbilling: Yup.number()
          .typeError("Digite um CEP válido")
          .test("len", "Digite um CEP válido", (val) => val.toString().length >= 8)
          .required("Campo Obrigatório"),
        numberbilling: Yup.number().typeError("Digite um número válido").required("Campo Obrigatório"),
        addressbilling: Yup.string().required("Campo Obrigatório"),
      });

      const dados = {
        adressbilling: data?.addressbilling || form.endereco.logradouro,
        bankCode: banco || null,
        bankAgency: data?.agenciaBancaria || null,
        bankAgencyDigit: data?.digitoAgencia || null,
        bankAccount: data?.contaBancaria || null,
        bankAccountDigit: data?.digitoConta || null,
        cepbilling: data?.cepbilling?.replace(/\D/g, "") || form.endereco?.cep,
        codplano: idProduto,
        complementbilling: data.complementbilling || "",
        dayInvoice: dataVencimento,
        dateToInstall: dayjs(date, "DD/MM/YYYY").format("YYYY-MM-DD"),
        invoicetype: fatura,
        fidelidade: haveFidelidade,
        numParcelas,
        numberbilling: Number(data.numberbilling) || Number(form.endereco.number),
        plano: produto.nomeProduto,
        id,
      };

      if (verifyDataInstalacao === "F" && !dataInstalacao) {
        return setSnackbar({
          open: true,
          message: "Você precisa selecionar um dia de instalação",
        });
      }

      if (!dataVencimento) {
        dispatch({ type: "diaFatura", payload: true });
        return setSnackbar({
          open: true,
          message: "Selecine o dia de vencimento da fatura",
        });
      }

      if (fatura === 1 && tipoFatura === 0) {
        await schema.validate(data, { abortEarly: false });
      }

      if (pagamento === "debito" && !banco) {
        return setSnackbar({
          open: true,
          message: "Selecine um banco",
        });
      }

      if (pagamento === "debito") {
        await schemaBank.validate(data, { abortEarly: false });
      }

      await Api.updateQuotation(dados);

      handleCaptureData({
        ...data,
        faturamento: {
          banco: banco || null,
          agenciaBancaria: data?.agenciaBancaria || null,
          contaBancaria: data?.contaBancaria || null,
          vencimento: dataVencimento,
          fidelidade,
          numParcelas,
          fatura,
          haveTaxa,
          dataInstalacao: date,
          endereco: {
            cep: data.cepbilling,
            logradouro: data.addressbilling,
            number: Number(data.numberbilling),
            complement: data.complementbilling,
          },
        },
      });

      handleNextStep({ activeStep: currentStep + 1 });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const { ...errors } = err;
        const errorMessages = {};

        errors.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      } else {
        const {
          response: { data: res },
        } = err;

        setSnackbar({ open: true, message: res.msg });
      }
    } finally {
      setLoading(false);
    }

    try {
      window.dataLayer.push({
        event: "faturamento",
        page_location: "https://vendas.liggavc.com.br",
        currency: "BRL",
        value: props?.produto?.precoProduto,
        items: [
          {
            item_id: Number(props?.idProduto),
            item_name: props?.produto?.nomeProduto,
          },
        ],
      });
    } catch (err) {}
  };
  return (
    <>
      <Form
        {...props}
        banks={banks}
        days={days}
        fatura={[fatura, setFatura]}
        faturaValidation={[state, dispatch]}
        fidelidade={[fidelidade, setFilidade]}
        feriados={feriados}
        form={form}
        formRef={formRef}
        dataVencimento={[dataVencimento, setDataVencimento]}
        FeriadosClass={FeriadosClass}
        handleSubmit={handleSubmit}
        loading={loading}
        loadingState={loadingState}
        parcela={[numParcelas, setNumParcelas]}
        produto={produto}
        snackbar={[snackbar, setSnackbar]}
        setDataInstalacao={setDataInstalacao}
        haveTaxa={[haveTaxa, setHaveTaxa]}
        setBanco={setBanco}
        tipoFatura={[tipoFatura, setTipoFatura]}
        tipoPagamento={[pagamento, setPagamento]}
        verifyDataInstalacao={[verifyDataInstalacao, setVerifyDataInstalacao]}
      />
    </>
  );
}

export default FaturamentoSteps;
