import React from "react";
import {
  Agrupament,
  Container,
  ContainerButton,
  Content,
  Divisor,
  Image,
  Tittle,
} from "./styles";

import Prancheta from "../../../assets/img/prancheta.png";
import CustomButton from "../../../components/CustomButton";

export default function Credito() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Container>
        <Tittle>Mas não desanime</Tittle>
        <Divisor />
        <Agrupament>
          <Content>
            Levar a nossa qualidade de conexão não é um trabalho fácil. Tem
            muito planejamento, investimento e detalhes envolvidos para garantir
            a melhor entrega.
            <br />
            <br />
            <br /> Estamos diariamente expandindo a nossa cobertura e um dos
            índices que ajuda no planejamento é a demanda de cada região.
          </Content>
          <Image id="img">
            <img src={Prancheta} alt="prancheta" />
          </Image>
        </Agrupament>
      </Container>
      <ContainerButton>
        <CustomButton id="naoDesanime" onClick={() => window.close()}>
          VOLTAR AO SITE
        </CustomButton>
      </ContainerButton>
    </div>
  );
}
