import React, { Component } from "react";

import Aside from "../containers/Aside";
import Steps from "../containers/Steps";

import { MainContainer, Copyright } from "./styles";

const asideSteps = (nome, step, avisame, protocolo, credito, isClienteNovaTelecom) => {
  const textAvisame = {
    greet: `Poxa, ${nome || " "}`,
    greetText: (
      <>
        <p>Infelizmente ainda não temos disponibilidade de nossos serviços em seu endereço.</p>

        <p>Mas não desanime, a sua demanda irá ajudar nosso time de expansão a levar a melhor internet para você.</p>
      </>
    ),
  };

  const textNovaTelecom = {
    greet: `Prezado(a) Cliente,`,
    greetText: (
      <>
        <p>
          A <span style={{ fontWeight: "bold" }}>Ligga</span> não tem recursos suficientes para o seu endereço.
          <br />
          <br />
          Mas não desanime, seu endereço já está disponivel na Nova Telecom. <br />
          <br />
          Por isso, aproveite para entrar em contato com a Nova Telecom e contratar a melhor internet do Paraná.
        </p>
      </>
    ),
  };

  const textCredito = {
    greet: `Poxa, ${nome || " "}`,
    greetText: (
      <>
        <p>
          A <span style={{ fontWeight: "bold" }}>Ligga</span> não tem recursos suficientes para o seu endereço.
        </p>
      </>
    ),
  };

  const textProtocol = {
    greet: `Parabéns ${nome || " "}!`,
    greetText: (
      <>
        <p>Você garantiu a melhor internet do Paraná na sua casa.</p>
      </>
    ),
  };

  const textArray = [
    {
      greet: `Se Ligga no que é importante pra você ;)`,
      greetText: (
        <>
          <p>
            Antes de tudo, precisamos verificar a disponibilidade de nossos serviços em sua região, tudo bem?
            <br />
            <br />
            Para isso vamos precisar de algumas informações para montarmos o seu carrinho de compras.
          </p>
        </>
      ),
    },
    {
      greet: `${nome || " "},`,
      greetText: (
        <>
          <p style={{ fontWeight: "bold" }}>SE LIGGA NO QUE É IMPORTANTE PARA VOCÊ!</p>
          <br />
          <p>
            Para seguirmos com a contratação, precisamos que você complemente as informações a seguir. Essas informações
            são importantes para a segurança do processo. ok?
          </p>
        </>
      ),
    },
    {
      greet: `${nome || " "}`,
      greetText: (
        <>
          <p>
            Agora chegou o momento de revisarmos o seu carrinho de compras.
            <br />
            <br />
            Aqui você precisa nos dizer qual a melhor data do vencimento da sua fatura e o endereço que temos que mandar
            ela, caso você opte pela fatura física. <br />
            <br />
            Dica: escolha a fatura digital, o meio ambiente agradece :)
          </p>
        </>
      ),
    },
    {
      greet: `Pronto ${nome || " "} !`,
      greetText: (
        <>
          <p>
            Você está a um clique de contratar a melhor internet do Paraná!
            <br />
            <br /> Aqui você vai revisar todos os dados da sua contratação e se estiverem corretos é só clicar em{" "}
            <span style={{ color: "#fff" }}>"Contratar minha internet"</span>.
          </p>
        </>
      ),
    },
    {
      greet: `Parabens ${nome || " "} !`,
      greetText: (
        <>
          <p>Você garantiu a melhor internet do Paraná na sua casa.</p>
        </>
      ),
    },
  ];

  if (step >= textArray.length) {
    return textArray.at(-1);
  }

  if (isClienteNovaTelecom) {
    return textNovaTelecom;
  }

  if (avisame) {
    return textAvisame;
  }

  if (!credito) {
    return textCredito;
  }

  if (protocolo) {
    return textProtocol;
  }

  return textArray[step];
};

class MainScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avisame: false,
      isClienteNovaTelecom: false,
      credito: true,
      gmapsLoaded: false,
      nome: "",
      protocolo: null,
      step: 0,
    };
  }

  componentDidMount() {
    window.initMap = this.initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?&key=AIzaSyCI2X-9O0vmEWu3FEMsGByaEYquTYfahB8&types=establishment&libraries=places&callback=initMap`;
    document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl);
  }

  initMap = () => {
    this.setState({
      gmapsLoaded: true,
    });
  };

  setNewNome = (newNome) => {
    this.setState({ nome: newNome });
  };

  setStepp = (newStep) => {
    this.setState({ step: newStep });
  };

  setAvisame = async (value) => {
    this.setState({ avisame: value });
  };

  setClienteNovaTelecom = async (value) => {
    this.setState({ isClienteNovaTelecom: value });
  };

  setCredito = (value) => {
    this.setState({ credito: value });
  };

  setProtocolo = async (value) => {
    this.setState({ protocolo: value });
  };

  render() {
    const { step, nome, avisame, protocolo, isClienteNovaTelecom, credito, gmapsLoaded } = this.state;
    const { themeAssets, companyName } = this.props;

    const setStep = step > 0 ? step - 1 : 0;

    const fullYear = new Date().getFullYear();
    const havePrev = !protocolo || !credito;

    return (
      gmapsLoaded && (
        <MainContainer>
          <Aside
            companyName="Ligga Telecom"
            contractType="Contratar"
            footerLogo={themeAssets.logo}
            greet={asideSteps(nome, step, avisame, protocolo, credito, isClienteNovaTelecom).greet}
            greetText={asideSteps(nome, step, avisame, protocolo, credito, isClienteNovaTelecom).greetText}
            handlePrev={() => this.setStepp(setStep)}
            havePrev={havePrev}
            logo={themeAssets.logo}
            nome={nome}
            product="Ligga"
          />
          <Steps
            {...this.props}
            setAvisame={this.setAvisame}
            setClienteNovaTelecom={this.setClienteNovaTelecom}
            setCredito={this.setCredito}
            setNewNome={this.setNewNome}
            setProtocolo={this.setProtocolo}
            setStepp={this.setStepp}
            step={step}
          />
          <Copyright>
            <img src={themeAssets.logo} alt={nome} />
            <p>
              © {fullYear} {companyName}. Todos os direitos reservados.
            </p>
          </Copyright>
        </MainContainer>
      )
    );
  }
}

export default MainScreen;
