import { axiosPrivate } from "../../../config/axios";
import { pMinDelay } from "../../../utils/promise";

export const updateQuotation = (values, delay) => {
  const response = axiosPrivate.put("/quotation/updateInVoice", values);
  return pMinDelay(response, delay);
};

export const fetchDays = (delay = 0) => {
  const response = axiosPrivate.get("/schedule/days");
  return pMinDelay(response, delay);
};

export const getBanks = (delay = 0) => {
  const response = axiosPrivate.get("/banks");
  return pMinDelay(response, delay);
};

export const getProdutoById = (id, delay = 0) => {
  const response = axiosPrivate.get(`/products/${id}`);
  return pMinDelay(response, delay);
};

export const getFeriados = (cep, delay = 0) => {
  const response = axiosPrivate.get(`/schedule/holidays/${cep}`);
  return pMinDelay(response, delay);
};
