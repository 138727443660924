import { axiosPrivate } from "../../../config/axios";
import { pMinDelay } from "../../../utils/promise";

export const getEndereco = (cep, delay) => {
  const response = axiosPrivate.get(`/address/cep/${cep}`);
  return pMinDelay(response, delay);
};
export const getProdutoById = (id, delay = 0) => {
  const response = axiosPrivate.get(`/products/${id}`);
  return pMinDelay(response, delay);
};

export const saveGravacao = (values, delay) => {
  const response = axiosPrivate.post(`/quotation`, values);
  return pMinDelay(response, delay);
};

export const getPlanos = (city, delay) => {
  const response = axiosPrivate.get(`/products/F/${city}`);
  return pMinDelay(response, delay);
};

export const getPlanosByLead = (city, idLead, delay) => {
  const response = axiosPrivate.get(`/products/F/${city}/${idLead}`);
  return pMinDelay(response, delay);
};

export const getComplementosByCep = (values, delay = 0) => {
  const response = axiosPrivate.post("/address/complements", values);
  return pMinDelay(response, delay);
};

export const getNovaAvailability = (cep) => {
  const response = axiosPrivate.get(`/address/cep/nova/${cep}`);
  return pMinDelay(response);
};
