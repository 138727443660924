import React from "react";
import { Container, Separator } from "./styles";

function ContentContainer({ title = "", children }) {
  return (
    <Container>
      <h2>{title}</h2>
      {title && <Separator />}
      {children}
    </Container>
  );
}

export default ContentContainer;
