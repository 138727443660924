import styled from "styled-components";

export const PrevButton = styled.div`
  margin-bottom: 30px;
  cursor: pointer;
`;

export const AsideLogo = styled.div`
  margin-bottom: 24px;
  img {
    display: block;
    width: 100px;
  }
`;

export const AsideContent = styled.div`
  align-items: center;
  position: absolute;
  top: 50%;
  margin-right: 30px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

export const CatchPhrase = styled.div`
  margin-bottom: 10px;
  h1 {
    color: ${({ theme }) => theme.colors.aside.headline};
    font-size: 35px;
    font-weight: bolder;
  }
`;

export const ProductSettings = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.aside.text};
  svg {
    margin-right: 5px;
    fill: ${({ theme }) => theme.colors.aside.text};
  }
`;

export const GreetContainer = styled.aside`
  margin-top: 40px;
  h2 {
    color: ${({ theme }) => theme.colors.aside.headline};
    font-size: 24px;
    font-weight: bolder;
    margin-bottom: 15px;
    color: #fff;

    @media (min-width: 900px) {
      font-size: 28px;
    }
    @media (min-width: 1300px) {
      font-size: 32px;
    }
  }
  p {
    color: ${({ theme }) => theme.colors.aside.text};
    font-size: 14px;
    @media (min-width: 1200px) {
      margin-right: 20px;
    }
  }
`;

export const AsideFooter = styled.footer`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  align-items: center;
  img {
    display: block;
    max-width: 80px;
    max-height: 80px;
    width: auto;
    height: auto;
    margin-bottom: 10px;
    filter: grayscale(1);
    opacity: 0.5;
  }
  p {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.aside.text};
  }
  @media (min-width: 800px) {
    display: block;
  }
`;

export const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  display: none;
  img {
    display: block;
    max-width: 50px;
    max-height: 50xpx;
    width: auto;
    height: auto;
    margin-bottom: 10px;
    filter: grayscale(1);
    opacity: 0.5;
  }
  p {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.aside.text};
  }
  @media (min-width: 800px) {
    display: block;
  }
`;

export const AsideContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  background: rgb(132, 105, 39);
  background: linear-gradient(
    151deg,
    rgba(132, 105, 39, 1) 7%,
    rgba(169, 39, 12, 1) 52%,
    rgba(171, 36, 11, 1) 80%,
    rgba(201, 82, 1, 1) 100%
  );
  border-bottom: 2px solid ${({ theme }) => theme.colors.aside.border};
  border-right: 0;
  padding: 30px 50px;
  min-height: 50vh;
  @media (min-width: 800px) {
    position: fixed;
    left: 0;
    min-height: 100vh;
    height: auto;
    padding: 40px;
    border-bottom: 0;
    width: 40%;
    svg {
      display: none;
    }
  }
  @media (min-width: 1025px) {
    padding: 40px 60px 30px 60px;
  }
  ${PrevButton} {
    display: none;
    svg {
      stroke: ${({ theme }) => theme.colors.aside.contrast};
    }
  }
`;

export const SvgContainer = styled.div`
  cursor: pointer;
`;
