import React, { useEffect } from "react";

import { Form as UnForm } from "@unform/web";
import { Snackbar } from "@mui/material";

import XInput from "../../../components/Form/input";
import GridContainer from "../../../components/GridContainer";
import GridItem from "../../../components/GridItem";
import CustomButton from "../../../components/CustomButton";
import XInputMask from "../../../components/Form/markedInput";
import Checkbox from "../../../components/Checkbox";

import { Description, Emphasis, Tittle } from "./styles";

const SNACKBAR_OPTIONS = {
  style: {
    ".MuiSnackbarContent-root": {
      background: "#E53935",
      color: "#fff",
      fontSize: "16px",
    },
  },
  orientation: {
    vertical: "bottom",
    horizontal: "center",
  },
};

export default function Form({ handleSubmit, setRedirect, formRef, ...props }) {
  const [acceptnewsletter, setAcceptNewsLetter] = props.acceptnewsletter;
  const [loading] = props.loading;
  const [snackbar, setSnackbar] = props.snackbar;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { form } = props;

  const initialState = {
    email: form.email,
    phonenumber: form.phonenumber,
  };

  const handleClose = () => {
    setSnackbar({ open: false });
  };

  return (
    <UnForm className="titleInitial" ref={formRef} onSubmit={handleSubmit} initialData={initialState}>
      <Snackbar
        anchorOrigin={SNACKBAR_OPTIONS.orientation}
        open={snackbar.open}
        sx={SNACKBAR_OPTIONS.style}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbar.message}
      />

      <Tittle>Avise-me</Tittle>

      <Description>
        Como já estamos com suas informações de contato, se preferir, clique em <Emphasis>AVISE-ME</Emphasis> que
        entraremos em contato assim que o serviço estiver disponível na sua região!
      </Description>

      <GridContainer>
        <GridItem xs="12" md="6" sx={{ marginTop: "20px" }}>
          <XInput name="email" length={80} title="E-mail" required />
        </GridItem>
        <GridItem xs="12" md="6" sx={{ marginTop: "20px" }}>
          <XInputMask mask="(99) 99999-9999" name="phonenumber" title="Telefone" placeholder="___________" required />
        </GridItem>
        <GridItem lg={12}>
          <Checkbox
            pointer
            checked={acceptnewsletter}
            onChange={(e) => setAcceptNewsLetter(e.target.checked)}
            label="Quero receber e-mails com novidades da Ligga"
          />
        </GridItem>
        <GridItem lg={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CustomButton id="aviseme" type="submit" disabled={loading} loading={loading} label="AVISE-ME" />
        </GridItem>
      </GridContainer>
    </UnForm>
  );
}
