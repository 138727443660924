import styled from "styled-components";

export const Banner = styled.div`
  display: flex;
  background: rgb(255, 128, 58);
  background: linear-gradient(
    172deg,
    rgba(255, 128, 58, 1) 0%,
    rgba(255, 181, 37, 1) 100%
  );
  height: 100px;
  border-radius: 15px 15px 0px 0px;
  justify-content: space-around;

  img {
    height: 100px;
    width: 120px;
    opacity: 10%;
  }

  @media (max-width: 900px) {
    img {
      display: none;
    }

    @media (max-width: 800px) {
      img {
        display: block;
      }
    }
  }

  @media (max-width: 350px) {
    img {
      display: none;
    }
  }
`;

export const CardItem = styled.div`
  width: auto;
  height: auto;
  border: 2px solid #e0e0e0;
  border-radius: 15px;

  @media (min-width: 800px) {
    width: 400px;
  }
`;

export const Divisor = styled.div`
  color: ${({ color }) => (color ? color : "#000")};
  padding: ${({ padding }) => padding && `${padding}px`};
  line-height: ${({ lineHeight }) => lineHeight && `${lineHeight}px`};
`;

export const Item = styled.li`
  color: #727272;
`;

export const ListItem = styled.ul``;

export const Price = styled.span`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize && `${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

export const SelectButton = styled.button`
  background: rgb(101, 174, 84);
  background: linear-gradient(
    172deg,
    rgba(101, 174, 84, 1) 0%,
    rgba(177, 193, 77, 1) 100%
  );
  border-radius: 40px;
  color: #fff;
  border: 1px solid transparent;
  height: 50px;
  font-weight: bold;
  width: 100%;
`;

export const Text = styled.div`
  padding: 20px 0px 20px 0px;
  text-align: center;
  color: ${(props) => props.color};
`;

export const ColorizedText = styled.span`
  color: ${({ color }) => color};
  font-weight: bold;
`;
