import { useReducer } from "react";

const initialState = {
  edificio: {
    name: "",
    unidade: 0,
    numero: 0,
  },
  condominio: {
    name: "",
    type: "",
    unidade: 0,
  },
  diaFaturaValidation: {
    diaFatura: false,
  },
};

export function useComplement() {
  const complementReducer = (state, { type, payload }) => {
    switch (type) {
      case "id":
        return { ...state, id: payload };
      case "unidade":
        return { ...state, unidade: payload };
      case "numero":
        return { ...state, numero: payload };
      case "name":
        return { ...state, name: payload };
      case "type":
        return { ...state, type: payload };
      default:
        return {};
    }
  };

  const [state, dispatch] = useReducer(
    complementReducer,
    initialState.edificio
  );

  return [state, dispatch];
}

export function useCondominio() {
  const condominioReducer = (state, { type, payload }) => {
    switch (type) {
      case "name":
        return { ...state, name: payload };
      case "id":
        return { ...state, id: payload };
      case "unidade":
        return { ...state, unidade: payload };
      case "numero":
        return { ...state, numero: payload };
      default:
        return {};
    }
  };

  const [state, dispatch] = useReducer(
    condominioReducer,
    initialState.edificio
  );

  return [state, dispatch];
}

export function useFaturaValidation() {
  const validationReduce = (state, { type, payload }) => {
    switch (type) {
      case "diaFatura":
        return { ...state, diaFatura: payload };
      default:
        return { ...state, diaFatura: null };
    }
  };

  const [state, dispatch] = useReducer(
    validationReduce,
    initialState.diaFaturaValidation
  );

  return [state, dispatch];
}
