import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "./form";

import * as Api from "./api";
import * as Yup from "yup";

import GoogleGeocode from "../../../components/GoogleGeocode";

function InformacoesSteps({ produto, ...props }) {
  const formRef = useRef(null);
  const recaptchaRef = useRef(null);

  const [acceptnewsletter, setAcceptNewsletter] = useState(true);
  const [cidadeCliente, setCidadeCliente] = useState("");
  const [endereco, setEndereco] = useState("cep");
  const [loadingDisponibilidade, setLoadingDisponibilidade] = useState(false);
  const [maps, setMaps] = useState({});
  const [recaptcha, setRecaptcha] = useState({ verify: false, error: "" });
  const [drawOpen, setDrawOpen] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [clienteNova, setClienteNova] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });

  const navigate = useNavigate();

  let enderecoFinal;

  const setDataProducts = async (product, idProduto) => {
    const { currentStep, handleNextStep, setProduto } = props;

    if (!idProduto) {
      await setProduto(product, product.id);
      navigate(`/${product.id}`, { replace: true });
      setDrawOpen(false);
    } else {
      await setProduto({ ...product, id: idProduto, codPlano: idProduto });
      setDrawOpen(false);
    }
    handleNextStep({ activeStep: currentStep + 1 });
  };

  const formatAddress = (address) => {
    return address.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const setNewException = (name, message) => {
    throw Object.assign(new Error(message), { name });
  };

  const getCidadesDiferentes = useCallback(() => {
    const cidadeProduto = produto.cidade?.toUpperCase();
    const cidadeEmComum =
      produto.cidades?.find((item) => item === cidadeCliente.toUpperCase()) || produto.cidade?.toUpperCase();

    if (!cidadeCliente) {
      return;
    }

    if (cidadeProduto && cidadeProduto.toUpperCase() !== cidadeCliente.toUpperCase()) {
      setNewException("city", "Cidade diferente do produto");
    }

    if (!cidadeEmComum || cidadeEmComum.toUpperCase() !== cidadeCliente.toUpperCase()) {
      setNewException("city", "Cidade");
    }
  }, [cidadeCliente, produto.cidade, produto.cidades]);

  const openDrawer = useCallback(
    async (idLead, isClienteNova = false) => {
      if (isClienteNova) {
        setClienteNova(true);
        setDrawOpen(true);
      } else {
        const cidadeFormat = cidadeCliente
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        try {
          const { idProduto } = props;
          if (idProduto) {
            try {
              const { data: product } = await Api.getProdutoById(idProduto);
              if (product) {
                setDataProducts(product, idProduto);
              }
            } catch (err) {
              const { data: produtosAPI } = await Api.getPlanosByLead(cidadeFormat, idLead);

              if (Array.isArray(produtosAPI.bel) && produtosAPI.bel.length) {
                setProdutos(produtosAPI.bel);
              } else {
                setProdutos([]);
              }

              setDrawOpen(true);
            }
          } else {
            const { data: produtosAPI } = await Api.getPlanosByLead(cidadeFormat, idLead);

            if (Array.isArray(produtosAPI.bel) && produtosAPI.bel.length) {
              setProdutos(produtosAPI.bel);
            } else {
              setProdutos([]);
            }

            setDrawOpen(true);
          }
        } catch (err) {}
      }
    },
    [cidadeCliente, setDrawOpen]
  );
  /////

  useEffect(() => {
    try {
      getCidadesDiferentes();
    } catch (err) {
      // if (err.name === "city") {
      //   openDrawer();
      // }
    }
  }, [cidadeCliente, getCidadesDiferentes, openDrawer]);

  const getCompleteAddress = async (address, number, idLogradouro) => {
    const geocode = GoogleGeocode;
    const { logradouro: rua } = address;
    let text;

    if (address && !rua) {
      return openSnackbar("Selecione seu endereço no mapa");
    }

    if (address.cidade && address.cep) {
      text = `${address.logradouro}, ${number} - ${address.bairro} - ${address.cidade}, ${address.estado}`;
    } else {
      text = rua.replace("-", `, ${number} -`);
    }

    try {
      const result = await geocode.getAddressByText(text);

      setCidadeCliente(formatAddress(result.cidade.toUpperCase()));

      enderecoFinal = result;
    } catch (err) {
      setSnackbar({ open: true, message: err.message });
    }
  };

  const getCoordinatesFromAddress = (cep, cardial) => {
    return GoogleGeocode.getCoordinatesByCep(cep, cardial);
  };

  const handleSubmit = async (data) => {
    const { handleCaptureData, setComplementos, setId, setNewNome, setViabilidade, setClientNovaTelecom, ip } = props;

    try {
      if (endereco !== "cep") {
        await getCompleteAddress(maps, data.endereco.number);
      }

      const basicSchema = Yup.object().shape({
        socialname: Yup.string().required("Campo obrigatório"),
        phonenumber: Yup.string()
          .transform((value) => value.replace(/\D/g, ""))
          .min(11, "Digite um número de celular válido")
          .required("Campo obrigatório"),
        email: Yup.string().email("Digite um e-mail válido").required("Campo obrigatório"),
        endereco: Yup.object().shape({
          number: Yup.string().required("Campo Obrigatório"),
        }),
      });

      const cepSchema = Yup.object().shape({
        socialname: Yup.string().required("Campo obrigatório"),
        phonenumber: Yup.string()
          .transform((value) => value.replace(/\D/g, ""))
          .min(11, "Digite um número de celular válido")
          .required("Campo obrigatório"),
        email: Yup.string().email("Digite um e-mail válido").required("Campo obrigatório"),
        endereco: Yup.object().shape({
          cep: Yup.string().required("Campo Obrigatório"),
          logradouro: Yup.string().required("Campo Obrigatório"),
          number: Yup.string().required("Campo Obrigatório"),
        }),
      });

      if (endereco === "cep") {
        await cepSchema.validate(data, { abortEarly: false });
      } else {
        const mapsSchema = {
          ...maps,
          phonenumber: data.phonenumber,
          socialname: data.socialname,
          email: data.email,
          endereco: {
            number: data.endereco?.number,
          },
        };

        await basicSchema.validate(mapsSchema, { abortEarly: false });
      }

      if (!recaptcha.verify) {
        return setRecaptcha({ error: "Verificação obrigatória" });
      }

      setLoadingDisponibilidade(true);

      const quotation = {
        socialname: data.socialname,
        email: data.email,
        cep: Number(data.endereco?.cep?.replace(/\D/g, "")) || Number(enderecoFinal.cep?.replace(/\D/g, "")),
        phonenumber: Number(data.phonenumber.replace(/\D/g, "")),
        number: Number(data.endereco?.number) || Number(enderecoFinal.endereco?.number),
        address: data.endereco?.logradouro || enderecoFinal.logradouro,
        complemento: data.complement || "",
        idLogradouro: data.endereco.codLogradouro,
        acceptcommunication: acceptnewsletter,
        latitude:
          enderecoFinal?.lat || (await getCoordinatesFromAddress(data.endereco?.cep?.replace(/\D/g, ""), "lat")),
        longitude:
          enderecoFinal?.lng || (await getCoordinatesFromAddress(data.endereco?.cep?.replace(/\D/g, ""), "lng")),
        cidade: cidadeCliente || enderecoFinal?.cidade,
        ip,
      };

      const { data: quotationResponse } = await Api.saveGravacao(quotation);

      if (quotationResponse.hasAvailability === false) {
        const { data: novaResponse } = await Api.getNovaAvailability(data.endereco.cep.replace(/\D/g, ""));
        await setClientNovaTelecom(novaResponse.cepViavel);
        if (novaResponse.cepViavel === true) {
          return openDrawer(0, novaResponse.cepViavel);
        }
      }

      await setId(quotationResponse.id);

      const { data: complementos } = await Api.getComplementosByCep({
        cep: quotation.cep,
        number: quotation.number,
      });

      setComplementos(complementos);

      handleCaptureData({
        ...quotation,
        endereco: {
          logradouro: quotation.address.toUpperCase(),
          cep: quotation.cep,
          number: quotation.number,
        },
      });

      setNewNome(data.socialname);

      setViabilidade(quotationResponse.hasAvailability);

      // handleNextStep({ activeStep: currentStep + 1 });
      openDrawer(quotationResponse.id);

      try {
        window.dataLayer.push({
          event: "verificar_disponibilidade",
          page_location: "https://vendas.liggavc.com.br",
          currency: "BRL",
          value: quotation.cep,
          items: [
            {
              item_id: quotationResponse.id,
              item_name: quotationResponse.hasAvailability ? "Disponível" : "Indisponível",
            },
          ],
        });
      } catch (err) {}
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const { ...errors } = err;
        const errorMessages = {};

        const {
          current: { setErrors },
        } = formRef;

        errors.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        setErrors(errorMessages);
      } else {
        const {
          response: { data: res },
        } = err;
        openSnackbar(res.msg || "Erro no servidor");
      }

      recaptchaRef.current.reset();
      setRecaptcha({ verify: false });
    } finally {
      setLoadingDisponibilidade(false);
    }
  };

  const openSnackbar = (message) => setSnackbar({ open: true, message });

  return (
    <>
      <Form
        {...props}
        acceptnewsletter={[acceptnewsletter, setAcceptNewsletter]}
        cidadeCliente={cidadeCliente}
        drawOpen={[drawOpen, setDrawOpen]}
        formatAddress={formatAddress}
        formRef={formRef}
        openDrawer={openDrawer}
        endereco={[endereco, setEndereco]}
        handleSubmit={handleSubmit}
        loadingDisponibilidade={loadingDisponibilidade}
        maps={maps}
        produtos={produtos}
        recaptcha={[recaptcha, setRecaptcha]}
        recaptchaRef={recaptchaRef}
        snackbar={[snackbar, setSnackbar, openSnackbar]}
        setDataProducts={setDataProducts}
        setMaps={setMaps}
        setCidadeCliente={setCidadeCliente}
        isClienteNova={clienteNova}
      />
    </>
  );
}

export default InformacoesSteps;
