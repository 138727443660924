import React, { Component } from "react";

import * as Api from "./api";

import ContentContainer from "./ContentContainer";
import Stepper from "../../components/Stepper";
import StepsContainer from "./styles";
import theme from "../../styles/theme";

import AvisemeSteps from "./AviseMe";
import Credito from "./Credito";
import ConclusaoSteps from "./Conclusao";
import DisponibilidadeSteps from "./Disponibilidade";
import FaturamentoSteps from "./Faturamento";
import InformacoesSteps from "./Informacoes";
import RevisaoSteps from "./Revisao";
import BackButton from "../../components/BackButton";

class Steps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      credito: true,
      diasInstalacao: 0,
      form: {},
      id: null,
      idProduto: null,
      ip: null,
      produto: {},
      protocolo: null,
      viabilidade: true,
      isClienteNovaTelecom: false,
      pagamento: "debito",
    };
  }

  componentDidMount() {
    const {
      params: { id },
    } = this.props;

    if (id) {
      this.setState({ idProduto: id });
    }

    this.getIp();
  }

  handleNextStep = (newStep) => {
    const { setStepp } = this.props;
    this.setState(newStep);
    setStepp(newStep.activeStep);
  };

  handleCaptureData = (data) => {
    this.setState({ form: { ...this.state.form, ...data } });
  };

  getIp = async () => {
    try {
      const { data } = await Api.getIp();

      let startIp = data.indexOf("ip") + 3;
      let endIp = data.indexOf("ts") - 1;

      let ip = data.substring(startIp, endIp);

      this.setState({ ip: ip });
    } catch (err) {
      this.setState({ ip: null });
    }
  };

  setComplementos = (value) => this.setState({ complementos: value });

  setCredito = (value) => {
    const { setCredito } = this.props;

    this.setState({ credito: value });
    setCredito(value);
  };

  setDiasInstalacao = (value) => this.setState({ diasInstalacao: value });

  setViabilidade = (value) => {
    const { setAvisame } = this.props;

    this.setState({ viabilidade: value });
    setAvisame(!value);
  };

  setClientNovaTelecom = (value) => {
    const { setClienteNovaTelecom } = this.props;

    this.setState({ isClienteNovaTelecom: value });
    setClienteNovaTelecom(value);
  };

  setPagamento = (value) => this.setState({ pagamento: value });

  setId = (newId) => this.setState({ id: newId });

  setProduto = (produto, id) => this.setState({ produto, idProduto: id });

  setProtocolo = (value) => {
    const { setProtocolo } = this.props;

    this.setState({ protocolo: value });
    setProtocolo(value);
  };

  render() {
    const {
      activeStep,
      credito,
      complementos,
      diasInstalacao,
      form,
      id,
      idProduto,
      ip,
      produto,
      protocolo,
      viabilidade,
      isClienteNovaTelecom,
    } = this.state;
    const { setNewNome, step } = this.props;

    if (activeStep !== step) {
      this.handleNextStep({ activeStep: step });
    }

    const stepAvise = {
      label: "Disponibilidade",
      content: (
        <ContentContainer title="2. Disponibilidade">
          <AvisemeSteps
            currentStep={activeStep}
            handleNextStep={this.handleNextStep}
            handleCaptureData={this.handleCaptureData}
            form={form}
            id={id}
            idProduto={idProduto}
          />
        </ContentContainer>
      ),
    };

    const stepDisponibilidade = {
      label: "Disponibilidade",
      content: (
        <ContentContainer title="2. Disponibilidade">
          <DisponibilidadeSteps
            complementos={complementos}
            currentStep={activeStep}
            form={form}
            handleNextStep={this.handleNextStep}
            handleCaptureData={this.handleCaptureData}
            setDiasInstalacao={this.setDiasInstalacao}
            setViabilidade={this.setViabilidade}
            id={id}
            produto={produto}
            setCredito={this.setCredito}
          />
        </ContentContainer>
      ),
    };

    const steps = [
      {
        label: "Informações",
        content: (
          <ContentContainer title="1. Informações">
            <InformacoesSteps
              {...this.state}
              currentStep={activeStep}
              handleNextStep={this.handleNextStep}
              handleCaptureData={this.handleCaptureData}
              produto={produto}
              setComplementos={this.setComplementos}
              setProduto={this.setProduto}
              setViabilidade={this.setViabilidade}
              setClientNovaTelecom={this.setClientNovaTelecom}
              setId={this.setId}
              ip={ip}
              form={form}
              idProduto={idProduto}
              setNewNome={setNewNome}
            />
          </ContentContainer>
        ),
      },
      ...(viabilidade ? [stepDisponibilidade] : [stepAvise]),
      ...(viabilidade && isClienteNovaTelecom === false
        ? [
            {
              label: "Faturamento",
              content: (
                <ContentContainer title="3. Faturamento">
                  <FaturamentoSteps
                    currentStep={activeStep}
                    handleNextStep={this.handleNextStep}
                    id={id}
                    idProduto={idProduto}
                    form={form}
                    produto={produto}
                    diasInstalacao={diasInstalacao}
                    handleCaptureData={this.handleCaptureData}
                    pagamento={this.state.pagamento}
                    setPagamento={this.setPagamento}
                  />
                </ContentContainer>
              ),
            },
            {
              label: "Revisão",
              content: (
                <ContentContainer title="4. Revisão">
                  <BackButton onClickFn={() => this.handleNextStep({ activeStep: activeStep - 1 })} />
                  <RevisaoSteps
                    currentStep={activeStep}
                    form={form}
                    handleNextStep={this.handleNextStep}
                    produto={produto}
                    setProtocolo={this.setProtocolo}
                    id={id}
                    handleCaptureData={this.handleCaptureData}
                    pagamento={this.state.pagamento}
                  />
                </ContentContainer>
              ),
            },
          ]
        : []),
    ];

    if (!protocolo && credito) {
      return (
        <>
          <StepsContainer>
            <Stepper steps={steps} activeStep={activeStep} filledcolor={theme.colors.stepper.filledcolor} />
          </StepsContainer>
        </>
      );
    } else if (!credito) {
      return <Credito currentStep={activeStep} form={form} />;
    }

    return (
      <StepsContainer>
        <ContentContainer>
          <ConclusaoSteps id={id} protocolo={this.state.protocolo} />
        </ContentContainer>
      </StepsContainer>
    );
  }
}

export default Steps;
