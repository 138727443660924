import React from "react";
import PropTypes from "prop-types";

import { CheckboxContainer, HiddenCheckbox, StyledCheckbox, Icon, Label } from "./styles";

const Checkbox = ({ className, checked, pointer, error, label, ...props }) => (
  <label>
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked} error={error} pointer={pointer}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
    <Label>{label}</Label>
  </label>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  pointer: PropTypes.bool,
  error: PropTypes.bool,
};

Checkbox.defaultProps = {
  pointer: false,
  error: false,
};

export default Checkbox;
