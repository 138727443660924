import React, { useEffect } from "react";
import { Form as UnForm } from "@unform/web";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import ModalFaturamento from "../../../components/ModalFaturamento";
import GridContainer from "../../../components/GridContainer";
import GridItem from "../../../components/GridItem";
import XInput from "../../../components/Form/input";
import CustomButton from "../../../components/CustomButton";
import { CustomInputSelect } from "../../../components/Select/styles";
import XRadioGroup from "../../../components/CustomRadio";
import ProductBanner from "../../../components/ProductBanner";
import XSelect from "../../../components/Select";

import { MenuItem, RadioGroup, FormControlLabel, Grid, Snackbar, InputLabel, Typography } from "@mui/material";

import { Chip, Paragraph, Info, ErrorFatura, Textoapoio } from "./styles";

const styles = {
  buttonSubsmit: { display: "flex", justifyContent: "flex-end" },
  chip: { marginLeft: 0, marginRight: 0 },
  diaFatura: {
    margin: "10px 0px",
    color: "#fff",
  },
  diaInstalacao: {
    marginTop: "10px",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#fff",
  },
  snackbar: {
    anchor: {
      vertical: "bottom",
      horizontal: "center",
    },
    style: {
      ".MuiSnackbarContent-root": {
        background: "#E53935",
        color: "#fff",
        fontSize: "16px",
      },
    },
  },
  tipoFatura: { marginTop: "10px" },
  tipoFaturaComponent: { marginLeft: 0, marginRight: 0 },
};

function Form({ formRef, handleSubmit, handleNextStep, currentStep, form, loading, produto, ...props }) {
  const [state, dispatch] = props.faturaValidation;
  const [dataVencimento, setDataVencimento] = props.dataVencimento;
  const [snackbar, setSnackbar] = props.snackbar;
  // eslint-disable-next-line no-unused-vars
  const [haveTaxa, setHaveTaxa] = props.haveTaxa;
  const [verifyDataInstalacao, setVerifyDataInstalacao] = props.verifyDataInstalacao;

  const { tipoPagamento } = props;
  const [pagamento, _] = tipoPagamento;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = () => {
    setSnackbar({ open: false });
  };

  const renderDiasInstalacao = () => {
    const { FeriadosClass } = props;
    dayjs.extend(customParseFormat);

    let dayArray = [];

    const now = dayjs(FeriadosClass.getDiaUtil(true), "DD/MM/YYYY");

    for (let i = 1; i <= 20; i++) {
      const newDate = dayjs(now.add(i, "day"), "DD/MM/YYYY");

      dayArray.push(FeriadosClass.getDiaUtil(false, newDate));
    }

    const sortedDays = dayArray.sort((a, b) => {
      const aDate = dayjs(a, "DD/MM/YYYY").unix();
      const bDate = dayjs(b, "DD/MM/YYYY").unix();

      return aDate - bDate;
    });

    return [...new Set(sortedDays)];
  };

  const renderDataInstalacao = () => {
    const { setDataInstalacao, FeriadosClass } = props;

    return (
      <div>
        <Paragraph size={22} color="#FF743C" gutter="10px 0px">
          Instalação
        </Paragraph>

        <Typography sx={{ fontSize: "20px" }} size={20} color="#fff">
          A sua data de instalação está prevista para{" "}
          <Info color="#FAA61A" bold>
            {FeriadosClass.getDiaUtil(true)}
          </Info>
          . Essa data atende a você?
        </Typography>

        <XRadioGroup
          name="endereço"
          onChange={setVerifyDataInstalacao}
          defaultValue="V"
          controlProps={{ sx: { color: "#fff" } }}
          items={[
            { value: "V", label: "SIM" },
            { value: "F", label: "NÃO" },
          ]}
        />

        {verifyDataInstalacao === "F" ? (
          <Grid item xs={6} md={6} sm={6} lg={6} sx={{ marginTop: "15px" }}>
            <InputLabel shrink sx={{ color: "#fff" }} htmlFor="diaInstalacao" required>
              Qual a melhor data de instalação?
            </InputLabel>
            <XSelect
              name="diaInstalacao"
              disabled={loading}
              input={<CustomInputSelect />}
              onChange={(e) => setDataInstalacao(renderDiasInstalacao()[e.target.value])}
            >
              {renderDiasInstalacao().map((item, index) => {
                return <MenuItem value={index}>{item}</MenuItem>;
              })}
            </XSelect>
          </Grid>
        ) : null}
      </div>
    );
  };

  const renderdataVencimento = () => {
    const { days } = props;

    return (
      <div>
        <Paragraph size={22} color="#FF743C" gutter="10px 0px">
          Fatura
        </Paragraph>

        <GridItem lg={12} sx={styles.diaFatura}>
          <Textoapoio>Qual a melhor data para o Vencimento da Fatura?</Textoapoio>
        </GridItem>
        <RadioGroup>
          <Grid item lg={6} md={12} xl={12}>
            {Array.isArray(days) && days.length
              ? days.map((item, index) => {
                  return (
                    <FormControlLabel
                      style={styles.chip}
                      key={index}
                      value={item}
                      control={
                        <Chip
                          error={state.diaFatura}
                          className={dataVencimento === item && "ativo"}
                          onClick={() => {
                            setDataVencimento(item);
                            dispatch({
                              type: "diaFatura",
                              payload: false,
                            });
                          }}
                        >
                          {item}
                        </Chip>
                      }
                    />
                  );
                })
              : "Loading..."}
          </Grid>
        </RadioGroup>

        {state.diaFatura && <ErrorFatura>Uma data deve ser selecionada</ErrorFatura>}
      </div>
    );
  };

  const renderMetodoPagamento = () => {
    const { banks, loadingState, setBanco } = props;
    // eslint-disable-next-line no-unused-vars
    const [pagamento, setTipoPagamento] = tipoPagamento;

    return (
      <>
        {pagamento === "debito" ? (
          <div>
            <Paragraph size={22} color="#FF743C" gutter="10px 0px">
              Faturamento
            </Paragraph>
            <Textoapoio className="textoapoio">Preencha os dados da conta bancária para débito automático</Textoapoio>

            <GridContainer spacing={3} columns={{ xs: 12, sm: 12, md: 12, xl: 12 }}>
              <GridItem xs={12} md={12} sm={12} lg={12}>
                <InputLabel shrink sx={styles.diaInstalacao} htmlFor="bancoPagamento" required>
                  Banco
                </InputLabel>
                <XSelect
                  name="bancoPagamento"
                  disabled={loadingState}
                  input={<CustomInputSelect />}
                  onChange={(e) => setBanco(e.target.value)}
                >
                  {banks?.map((item) => (
                    <MenuItem key={item.codigo} value={item.codigo}>
                      {item.nome}
                    </MenuItem>
                  ))}
                </XSelect>
              </GridItem>
              <GridItem xs={8} md={8} sm={8} lg={8}>
                <XInput
                  name="agenciaBancaria"
                  title="Agência"
                  placeholder="Somente números"
                  length={20}
                  disabled={loadingState}
                  required
                />
              </GridItem>
              <GridItem xs={4} md={4} sm={4} lg={4}>
                <XInput name="digitoAgencia" title="Dígito" length={20} disabled={loadingState} />
              </GridItem>
              <GridItem xs={8} md={8} sm={8} lg={8}>
                <XInput
                  name="contaBancaria"
                  title="Conta"
                  length={20}
                  placeholder="Somente números"
                  disabled={loadingState}
                  required
                />
              </GridItem>
              <GridItem xs={4} md={4} sm={4} lg={4}>
                <XInput name="digitoConta" title="Dígito" length={20} disabled={loadingState} />
              </GridItem>
            </GridContainer>
          </div>
        ) : (
          <div>
            <Paragraph size={22} color="#FF743C" gutter="10px 0px">
              Faturamento
            </Paragraph>
            <Textoapoio className="textoapoio">Forma de pagamento selecionada: Boleto</Textoapoio>
          </div>
        )}
      </>
    );
  };

  const initialData = {
    cepbilling: form.endereco.cep,
    addressbilling: form.endereco.logradouro,
    numberbilling: form.endereco.number,
    complementbilling: form.complement,
  };

  return (
    <UnForm className="titleInitial" ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
      <Snackbar
        anchorOrigin={styles.snackbar.anchor}
        open={snackbar.open}
        sx={styles.snackbar.style}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbar.message}
      />

      <GridContainer spacing={3} columns={{ xs: 12, sm: 12, md: 12 }}>
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <Paragraph size={22} color="#FF743C" weigth="bold">
            Produto
          </Paragraph>
        </Grid>
        <GridItem xs={12} md={12} sm={12} lg={6} xl={6}>
          <ProductBanner taxaInstalacao={haveTaxa} produto={produto} opcionais={form.opcionais} pagamento={pagamento} />
        </GridItem>

        <GridItem lg={12}>{renderDataInstalacao()}</GridItem>
        <GridItem lg={12}>{renderdataVencimento()}</GridItem>
        <GridItem xs={12} md={12} sm={12} lg={12}>
          {renderMetodoPagamento()}
        </GridItem>

        <GridItem>
          <ModalFaturamento {...props} loading={loading} produto={produto} form={form} formRef={formRef} />
        </GridItem>

        <Grid item lg={12} xs={12} sm={12} sx={styles.buttonSubsmit}>
          <CustomButton
            id="faturamento"
            type="submit"
            disabled={loading}
            loading={loading}
            label="Confirmar Informações"
          />
        </Grid>
      </GridContainer>
    </UnForm>
  );
}

export default Form;
