import React from "react";

import GridItem from "../../../../components/GridItem";

import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
  AiFillLinkedin,
} from "react-icons/ai";

import { ButtonGroup, Container, CustomButton, Label } from "./styles";

export default function Contato({ ...props }) {
  return (
    <>
      <Container>
        <Label>
          Entraremos em contato quando nossos serviços estiverem disponíveis em
          sua região, até lá acompanhe as novidades da Ligga em nossas redes
          sociais:
        </Label>

        <ButtonGroup>
          <GridItem
            lg={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <CustomButton
              gutterTop="30px"
              onClick={() => window.open("https://www.facebook.com/liggavc/")}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
              >
                <AiFillFacebook size={30} />
              </div>
              <span>Facebook</span>
            </CustomButton>
          </GridItem>
          <GridItem
            lg={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <CustomButton
              gutterTop="10px"
              onClick={() => window.open("https://www.instagram.com/liggavc/")}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
              >
                <AiFillInstagram size={30} />
              </div>
              <span>Instagram</span>
            </CustomButton>
          </GridItem>
          <GridItem
            lg={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <CustomButton
              gutterTop="10px"
              onClick={() =>
                window.open("https://www.youtube.com/liggatelecom")
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
              >
                <AiFillYoutube size={30} />
              </div>
              <span>Youtube</span>
            </CustomButton>
          </GridItem>
          <GridItem
            lg={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <CustomButton
              gutterTop="10px"
              onClick={() => window.open("https://twitter.com/ligga_vc")}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
              >
                <AiOutlineTwitter size={30} />
              </div>
              <span>Twitter</span>
            </CustomButton>
          </GridItem>
          <GridItem
            lg={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <CustomButton
              gutterTop="10px"
              onClick={() =>
                window.open("https://www.linkedin.com/company/liggavc")
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
              >
                <AiFillLinkedin size={30} />
              </div>
              <span>Linkedin</span>
            </CustomButton>
          </GridItem>
        </ButtonGroup>
      </Container>

      <GridItem lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
        <CustomButton
          id="redesSociais"
          gutterTop="20px"
          onClick={() => window.open("https://liggavc.com.br/")}
        >
          VOLTAR AO SITE
        </CustomButton>
      </GridItem>
    </>
  );
}
