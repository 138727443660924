import React from "react";
import PropTypes from "prop-types";

import GridItem from "../GridItem";

import { Banner, CardItem, ColorizedText, Divisor, Item, ListItem, Price, SelectButton, Text } from "./styles";

const styles = { gridItem: { marginTop: "10px" } };

const Card = ({ ...props }) => {
  const { onClick, item, index } = props;

  const renderPrice = () => {
    const price = item.precoProdutoComDesconto || item.precoProduto;

    const priceFixed = price.toFixed(2);

    return priceFixed.replace(".", ",");
  };

  return (
    <GridItem lg={10} sx={styles.gridItem}>
      <CardItem key={index}>
        <Banner>
          <Text color="#ffff">
            {item.promocao} <br />
            {item.nomeProduto} <br />
            por R$
            <Price fontSize={30} fontWeight="bold" color="#fff">
              {renderPrice()}
            </Price>
            /mês
          </Text>
        </Banner>

        <Divisor padding={20} color="#455A64" lineHeight={18}>
          <ListItem>
            <Item>
              - Download de até <ColorizedText color="#ff6f00">{item.download}</ColorizedText>
            </Item>
            <Item>
              - Upload de até <ColorizedText color="#ff6f00">{item.upload}</ColorizedText>
            </Item>
            <Item>- Wi-Fi Grátis</Item>
            <Item>- 100% Fibra Óptica</Item>
            {item.servicosObrigatorios && item.servicosObrigatorios.length > 0 && (
              <Item>- {item.servicosObrigatorios.map((i) => i.descricao).join(", ")}</Item>
            )}
          </ListItem>
          <br />
          <SelectButton onClick={() => onClick(item)}>SELECIONAR</SelectButton>
        </Divisor>
      </CardItem>
    </GridItem>
  );
};

Card.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

Card.defaultProps = {
  item: null,
};

export default Card;
