import React from "react";

import { Form as UnForm } from "@unform/web";

import {
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Snackbar,
} from "@mui/material";

import XInput from "../../../components/Form/input";
import XSelect from "../../../components/Select";
import GridContainer from "../../../components/GridContainer";
import GridItem from "../../../components/GridItem";
import XInputMask from "../../../components/Form/markedInput";
import CustomButton from "../../../components/CustomButton";

import { CustomInputSelect } from "../../../components/Select/styles";
import { isValidArray } from "../../../utils/array";

const styles = {
  andar: {
    fontWeight: "bold",
    fontSize: "16px",
    color: "#fff",
  },
  condominioHorizontal: { color: "#727272", fontWeight: "bold", margin: "5px" },
  condominioUnidade: {
    fontWeight: "bold",
    fontSize: "16px",
  },
  edificio: {
    fontWeight: "bold",
    fontSize: "16px",
    color: "#fff",
  },
  residencia: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: "16px",
    position: "relative",
    transform: "translate(0, -1.5px) scale(0.75)",
    "&.Mui-focused": {
      color: "#ff6f00",
    },
  },
  snackbar: {
    style: {
      ".MuiSnackbarContent-root": {
        background: "#E53935",
        color: "#fff",
        fontSize: "16px",
      },
    },
    anchor: {
      vertical: "bottom",
      horizontal: "center",
    },
  },
  submit: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  unidade: {
    fontWeight: "bold",
    fontSize: "16px",
    color: "#fff",
  },
};

function Form({
  complementos,
  formRef,
  handleClose,
  handleChangeResidencia,
  handleSubmit,
  form,
  setCredito,
  loading,
  renderDrawer,
  snackbar,
  tipoImovel,
  ...props
}) {
  const [state, dispatch] = props.complementReducer;

  const initialData = {
    cep: form.endereco.cep,
    number: form.endereco.number,
    logradouro: form.endereco.logradouro,
  };

  const renderOptionsComplement = () => {
    const filteredComplement = complementos.find(
      (item) => item.condominioID === state.id
    );

    const { unidades } = filteredComplement;

    if (isValidArray(unidades)) {
      return unidades.map((item) => (
        <MenuItem value={item.andar}>{item.andar}</MenuItem>
      ));
    }
    return <MenuItem value={0}>Sem andar</MenuItem>;
  };

  const renderUnidades = () => {
    const { unidades } = complementos.find(
      (item) => item.condominioID === state.id
    );

    const { units } = unidades.find((item) => item.andar === state.unidade);

    if (isValidArray(units)) {
      return units.map((item) => (
        <MenuItem value={item.unit}>{item.unit}</MenuItem>
      ));
    }
    return <MenuItem value={0}>Sem unidade</MenuItem>;
  };

  const setCondominio = (e) => {
    const { action } = props;
    const value = e.target.value;

    action({ type: "id", payload: value.condominioID });
    action({ type: "unidade", payload: value.name });
    action({ type: "name", payload: value.type });
  };

  const setEdificio = (e) => {
    const value = e.target.value;

    dispatch({ type: "id", payload: value.condominioID });
    dispatch({ type: "name", payload: value.name });
    dispatch({ type: "type", payload: value.type });
  };

  const renderSelectImovel = () => {
    const defaultValue = tipoImovel || 1;

    return (
      <Grid item xs={12} md={12} sm={12} lg={6}>
        <FormControl fullWidth>
          <InputLabel sx={styles.residencia} shrink id="residencia">
            Tipo do Imóvel
          </InputLabel>
          <XSelect
            labelId="residencia"
            value={defaultValue}
            displayEmpty
            input={<CustomInputSelect />}
            disabled={loading || complementos?.length}
            onChange={handleChangeResidencia}
          >
            <MenuItem value={1}>Casa/Sobrado</MenuItem>
            <MenuItem value={2}>Edíficio</MenuItem>
            <MenuItem value={3}>Condomínio</MenuItem>
          </XSelect>
        </FormControl>
      </Grid>
    );
  };

  const renderTipoEdificio = () => {
    return (
      <>
        <Grid item xs={12} md={12} sm={12} lg={6}>
          <InputLabel shrink htmlFor="edificio" required sx={styles.edificio}>
            Selecione uma Unidade
          </InputLabel>
          <XSelect
            name="edificio"
            disabled={loading}
            defaultValue={null}
            input={<CustomInputSelect />}
            onChange={setEdificio}
          >
            {isValidArray(complementos) ? (
              complementos.map((item) => (
                <MenuItem key={item.name + item.condominioID} value={item}>
                  {`${item.type} ${item.name}`}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={0}>Sem unidade</MenuItem>
            )}
          </XSelect>
        </Grid>

        {state.id ? (
          <Grid item xs={12} md={12} sm={12} lg={6}>
            <InputLabel shrink sx={styles.andar} required htmlFor="andar">
              Selecione um Andar
            </InputLabel>
            <XSelect
              name="andar"
              disabled={loading}
              defaultValue={null}
              input={<CustomInputSelect />}
              onChange={(e) =>
                dispatch({ type: "unidade", payload: e.target.value })
              }
            >
              {renderOptionsComplement()}
            </XSelect>
          </Grid>
        ) : null}

        {state.unidade ? (
          <Grid item xs={12} md={12} sm={12} lg={6}>
            <InputLabel shrink sx={styles.unidade} required htmlFor="unidade">
              Selecione o número
            </InputLabel>
            <XSelect
              name="unidade"
              disabled={loading}
              defaultValue={null}
              input={<CustomInputSelect />}
              onChange={(e) =>
                dispatch({ type: "numero", payload: e.target.value })
              }
            >
              {renderUnidades()}
            </XSelect>
          </Grid>
        ) : null}
      </>
    );
  };

  const renderTipoCondominio = () => (
    <>
      <Grid item xs={12} md={12} sm={12} lg={6}>
        <InputLabel
          shrink
          sx={styles.condominioUnidade}
          htmlFor="condominioUnidade"
          required
        >
          Selecione uma Unidade
        </InputLabel>
        <XSelect
          name="condominioUnidade"
          disabled={loading}
          input={<CustomInputSelect />}
          displayEmpty
          onChange={setCondominio}
        >
          {isValidArray(complementos) ? (
            complementos
              .sort((a, b) => a.name - b.name)
              .map((item) => (
                <MenuItem key={item.condominioID} value={item}>
                  {`${item.name} - ${item.type}`}
                </MenuItem>
              ))
          ) : (
            <MenuItem value={0}>Nenhuma opção</MenuItem>
          )}
        </XSelect>
      </Grid>

      {!complementos.length && tipoImovel !== 1 ? (
        <>
          <GridItem xs={12}>
            <p style={styles.condominioHorizontal}>Condominio Horizontal</p>
          </GridItem>

          <GridItem xs={12} lg={3}>
            <XInput name="nameCondominio" title="Nome do Condominio" required />
          </GridItem>

          <GridItem xs={12} lg={3}>
            <XInputMask
              mask="9999"
              name="qtdCasas"
              title="Quantidade de Casas do Condominio"
              required
            />
          </GridItem>
        </>
      ) : null}
    </>
  );

  return (
    <UnForm ref={formRef} initialData={initialData} onSubmit={handleSubmit}>
      <Snackbar
        anchorOrigin={styles.snackbar.anchor}
        open={snackbar.open}
        sx={styles.snackbar.style}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbar.message}
      />

      {renderDrawer()}

      <GridContainer>
        <GridItem xs={12} md={8}>
          <XInput name="fullname" title="Nome Completo" required />
        </GridItem>
        <GridItem xs={12} md={4}>
          <XInputMask
            mask="99/99/9999"
            name="birthdate"
            title="Data Nascimento"
            required
          />
        </GridItem>

        <GridItem xs={12} md={4}>
          <XInputMask mask="999.999.999-99" name="cpf" title="CPF" required />
        </GridItem>
        <GridItem xs={12} md={8}>
          <XInput name="mother" title="Nome completo da mãe" required />
        </GridItem>

        <GridItem xs={12} md={3}>
          <XInputMask
            mask="99999-999"
            name="cep"
            title="CEP"
            disabled
            placeholder="_____-___"
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <XInput name="logradouro" disabled title="Endereço" />
        </GridItem>
        <GridItem xs={12} md={3}>
          <XInput name="number" disabled title="Número" />
        </GridItem>

        {renderSelectImovel()}

        {tipoImovel === 2 && renderTipoEdificio()}
        {tipoImovel === 3 && renderTipoCondominio()}

        {tipoImovel && (
          <Grid item xs={12} md={12} sm={12} lg={6}>
            <XInput
              name="complement"
              title="Ponto de Referência"
              required={tipoImovel !== 1 && !complementos.length}
            />
          </Grid>
        )}
      </GridContainer>

      <GridItem lg={12} style={styles.submit}>
        <CustomButton
          id="disponibilidade"
          type="submit"
          disabled={loading}
          loading={loading}
          label="Confirmar Informações"
        />
      </GridItem>
    </UnForm>
  );
}
export default Form;
