import React from "react";

import GridContainer from "../../../components/GridContainer";
import GridItem from "../../../components/GridItem";
import CustomButton from "../../../components/CustomButton";
import Checkbox from "../../../components/Checkbox";

import { FaGooglePlay, FaApple } from "react-icons/fa";
import MockupApp from "../../../assets/img/mockup-app.png";

import {
  ButtonStore,
  ButtonStoreContainer,
  CheckboxContainer,
  Footer,
  FooterContent,
  Header,
  Protocolo,
  ProtocoloContainer,
  StoreButton,
  SubTitle,
  TextStoreButton,
} from "./styles";

import { ENDERECO_SITE, URL_APPLE_STORE, URL_PLAY_STORE } from "../../../shared/links";

import { ALERTA_EMAIL, APROVEITE_APLICATIVO } from "../../../shared/message";
import { Image } from "./styles";

const STORES = {
  GOOGLE_PLAY: URL_PLAY_STORE,
  APPLE_STORE: URL_APPLE_STORE,
};

const styles = {
  checkbox: { marginBottom: "20px" },
  footer: {
    border: "1px solid #E0E0E0",
    width: "100%",
    padding: "30px",
    position: "relative",
    "@media (max-width: 500px)": {
      margin: "15px 0 0 8px",
    },
    "@media (min-width: 500px) and (max-width: 800px)": {
      margin: "30px",
    },
  },
  button: { display: "flex", justifyContent: "flex-end" },
};

function XGridItem({ children, ...props }) {
  const gridConfigs = { xs: 12, sm: 12, md: 12, xl: 12, lg: 12 };

  return (
    <GridItem {...gridConfigs} {...props}>
      {children}
    </GridItem>
  );
}

function PlayStoreButton() {
  return (
    <StoreButton onClick={() => window.open(STORES.GOOGLE_PLAY)}>
      <FaGooglePlay color="#FF6F00" size={25} />
      <TextStoreButton>Google Play</TextStoreButton>
    </StoreButton>
  );
}

function AppleStoreButton() {
  return (
    <StoreButton onClick={() => window.open(STORES.APPLE_STORE)}>
      <FaApple color="#FF6F00" size={25} />
      <TextStoreButton>Apple Store</TextStoreButton>
    </StoreButton>
  );
}

export default function Form({ protocolo }) {
  return (
    <GridContainer>
      <XGridItem>
        <Header>Número de Protocolo</Header>
      </XGridItem>

      <XGridItem>
        <ProtocoloContainer>
          <Protocolo>{protocolo}</Protocolo>
        </ProtocoloContainer>
      </XGridItem>

      <XGridItem>
        <SubTitle>{ALERTA_EMAIL}</SubTitle>
      </XGridItem>

      <CheckboxContainer>
        <XGridItem sx={styles.checkbox}>
          <Checkbox checked label="Número do contrato" />
        </XGridItem>
        <XGridItem sx={styles.checkbox}>
          <Checkbox checked label="Serviço contratado" />
        </XGridItem>
        <XGridItem sx={styles.checkbox}>
          <Checkbox checked label="Endereço de instalação" />
        </XGridItem>
        <XGridItem sx={styles.checkbox}>
          <Checkbox checked label="Data de instalação" />
        </XGridItem>
      </CheckboxContainer>

      <GridItem row sx={styles.footer}>
        <Image id="img">
          <img src={MockupApp} alt="mockupapp" />
        </Image>
        <Footer>
          <FooterContent>{APROVEITE_APLICATIVO}</FooterContent>
        </Footer>

        <ButtonStoreContainer>
          <ButtonStore>
            <PlayStoreButton />
          </ButtonStore>

          <ButtonStore>
            <AppleStoreButton />
          </ButtonStore>
        </ButtonStoreContainer>
      </GridItem>

      <XGridItem sx={styles.button}>
        <CustomButton id="protocoloVenda" onClick={() => window.open(ENDERECO_SITE)} label="VOLTAR AO SITE" />
      </XGridItem>
    </GridContainer>
  );
}
