import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

function GridItem({ children, ...rest }) {
  return (
    <Grid item {...rest}>
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GridItem;
