import styled from "styled-components";

export const Bold = styled.span`
  font-weight: bold;
  ${({ size }) => size && `font-size: ${size}px`}
`;

export const CardContainer = styled.div`
  border: 2px solid ${({ ativo }) => (ativo ? "#FF6F00" : "#e0e0e0")};
  color: #727272;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 15px 0px;
  height: auto;
  padding: 10px;
  min-height: 110px;
  user-select: none;
  transition: border-color 0.3s ease-in-out;

  :hover {
    border-color: #faa61a;
  }
`;

export const ColorizePrice = styled.span`
  color: #ff6f00;
  font-weight: bold;
`;

export const DisplayAdd = styled.button`
  width: 33px;
  border: none;
  color: #a2acb1;
  font-size: 20px;
  cursor: pointer;

  :disabled {
    color: #cfd8dc;
  }
`;

export const DisplayContainer = styled.div`
  margin-top: 10px;
  border-radius: 3px;
  border: 1px solid #cfd8dc;
  display: flex;
  width: 100px;
  height: 40px;
`;

export const DisplayNumber = styled.div.attrs({ disabled: true })`
  width: 33px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: not-allowed;
`;

export const DisplayRemove = styled.button`
  width: 33px;
  border: none;
  color: #a2acb1;
  font-size: 20px;
  cursor: pointer;

  :disabled {
    color: #cfd8dc;
  }
`;

export const DisplayExtraFields = styled.div`
  width: 100%;
`;

export const SelectContainer = styled.div`
  padding: 20px;
  cursor: pointer;
`;

export const InformacoesContainer = styled.div`
  padding: 10px;
`;

export const ProdutosContainer = styled.div`
  margin-top: 10px;
`;

export const ProdutoNameTached = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 29px;

  background: linear-gradient(180deg, #ff6f00 14.58%, #f8c44c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const TittleOpcional = styled.div`
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  @media (min-width: 1000px) {
    img {
      width: 200px;
    }
  }
  @media (min-width: 700px) {
    img {
      width: 140px;
    }
  }
  @media (max-width: 700px) {
    img {
      width: 120px;
    }
  }
`;

export const RadioButton = styled.div`
  height: 30px;
  width: 30px;
  border: ${({ ativo }) => (ativo ? "none" : "7px solid #e0e0e0")};
  border-radius: 50%;
  background-color: ${({ ativo }) => ativo && "#ff6f00"};
  transition: border 1s ease-in-out;
`;
