import styled from "styled-components";

export const ActionForm = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  padding-left: 16px;

  .recaptcha {
    span {
      display: flex;
      justify-content: center;
    }
  }

  button {
    margin-bottom: ${(props) => (props.recaptchaError ? "20px" : "0px")};
    min-width: 50px;
    width: 100%;
  }
  @media (min-width: 800px) {
    flex-direction: column;
    button {
      width: auto;
    }
  }
  @media (min-width: 901px) {
    flex-direction: row;
  }
`;

export const GridItem = styled.div`
  color: #fff;
`;

export const DrawerContainer = styled.div`
  padding: 30px;
  width: auto;
  background-color: #fff;
  background: ${({ $isClienteNova }) =>
    $isClienteNova
      ? "linear-gradient(151deg, rgba(132,105,39,1) 7%, rgba(169,39,12,1) 52%, rgba(171,36,11,1) 80%, rgba(201,82,1,1) 100%)"
      : "#fff"};

  @media (min-width: 80%) {
    width: 50vw;
    .items {
      display: flex;
      justify-content: space-around;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100dvh - 60px);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 30px;
  border: 2px solid #ccc;
  gap: 20px;
  background-color: #fff;

  @media (min-width: 800px) {
    width: 80%;
  }
`;

export const SvaImage = styled.img`
  width: 38px;
  height: 38px;
`;

export const ButtonWhats = styled.button`
  outline: none;
  border-radius: 10px;
  border: 2px solid #ccc;
  padding: 10px;
  color: #666;
  font-size: 22px;
  margin: 20px;
  width: 50%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  @media (max-width: 800px) {
    width: 80%;
  }
`;

export const MapsInputContainer = styled.div`
  position: absolute;
  margin: 30px 0px 0px 10px;
`;

export const BigTitle = styled.p`
  font-size: ${({ size }) => (size ? size : "20px")};
  font-weight: bold;
  color: #ff5f1e;
  ${({ gutterBottom }) => gutterBottom && ` margin-bottom: ${gutterBottom}px`};
  ${({ gutter }) => gutter && ` margin: ${gutter}`};
`;

export const RecaptchaError = styled.span`
  color: #f00;
  margin-top: 5px;
`;

export const RecaptchaBox = styled.div`
  ${({ recaptchaError }) =>
    recaptchaError &&
    `
border: 2px solid #f00;
border-radius: 6px;
width: auto;
`}
`;
