import React, { useState } from "react";

import { Form as UnForm } from "@unform/web";
import { Scope } from "@unform/core";
import Recaptcha from "react-google-recaptcha";

import {
  ActionForm,
  BigTitle,
  DrawerContainer,
  Wrapper,
  SvaImage,
  CardBox,
  ButtonWhats,
  MapsInputContainer,
  RecaptchaBox,
  RecaptchaError,
} from "./styles";

import Card from "../../../components/Card";
import Checkbox from "../../../components/Checkbox";
import CustomButton from "../../../components/CustomButton";
import GridContainer from "../../../components/GridContainer";
import GridItem from "../../../components/GridItem";
import XInput from "../../../components/Form/input";
import XInputMask from "../../../components/Form/markedInput";
import GoogleAutoComplete from "../../../components/GoogleAutoComplete";
import GoogleGeocode from "../../../components/GoogleGeocode";
import Maps from "../../../components/Maps";
import MapsInput from "../../../components/MapsInput";

import WhatsApp from "../../../assets/icons/whats.png";

import * as messages from "../../../shared/message";
import * as Api from "./api";

import { Snackbar, Drawer } from "@mui/material";
import XRadioGroup from "../../../components/CustomRadio";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA;

const isMobile = window.innerWidth < 800;

const SNACKBAR_OPTIONS = {
  style: {
    ".MuiSnackbarContent-root": {
      background: "#E53935",
      color: "#fff",
      fontSize: "16px",
    },
  },
  orientation: {
    vertical: isMobile ? "top" : "bottom",
    horizontal: "center",
  },
};

function Form({
  cidadeCliente,
  formatAddress,
  formRef,
  idProduto,
  ip,
  handleCaptureData,
  handleNextStep,
  handleSubmit,
  loadingDisponibilidade,
  maps,
  produtos,
  recaptchaRef,
  setComplementos,
  setNewNome,
  openDrawer,
  isClienteNova,
  ...props
}) {
  const [disabled, setDisabled] = useState(false);
  const [logradouro, setRua] = useState("");

  const [drawOpen, setDrawOpen] = props.drawOpen;
  const [snackbar, setSnackbar, openSnackbar] = props.snackbar;
  const [recaptcha, setRecaptcha] = props.recaptcha;
  const [endereco, setEndereco] = props.endereco;
  const [acceptnewsletter, setAcceptNewsletter] = props.acceptnewsletter;

  const handleChangePlaces = async (address) => {
    const { setCidadeCliente, setMaps } = props;

    const cidade = address?.logradouro?.split("-")?.at(1).split(",")?.at(1);

    try {
      if (cidade) {
        await setCidadeCliente(formatAddress(cidade.toUpperCase().trim()));
        setMaps(address);
      }
    } catch (err) {
      openSnackbar(err.message);
    }
  };

  const handleChangeCoordinates = async (_e, _props, data) => {
    const coordinates = { lat: data.latLng.lat(), lng: data.latLng.lng() };

    getAddressFromCoordinates(coordinates);
  };

  const getAddressFromCoordinates = async (coordinates) => {
    const { setCidadeCliente, setMaps } = props;
    const geocode = GoogleGeocode;

    try {
      const result = await geocode.getAddressByCoordinates(coordinates);

      await setMaps({
        ...coordinates,
        logradouro: `${result.logradouro} - ${result.bairro} - ${result.cidade}, ${result.estado}`,
      });

      await setCidadeCliente(formatAddress(result.cidade.toUpperCase()));
    } catch (err) {
      openSnackbar(err.message);
    }
  };

  const handleClose = () => {
    setSnackbar({ open: false });
  };

  const handleCloseDrawer = () => {
    if (Array.isArray(produtos) && produtos.length) {
      if (produtos.find((item) => item.id === idProduto)) {
        setDrawOpen(false);
      }
    } else {
      setDrawOpen(false);
    }
  };

  const handleCidadeCliente = async (data) => {
    const { setCidadeCliente } = props;

    const city = data.length && data?.at().city;

    if (city) {
      await setCidadeCliente(formatAddress(city?.toUpperCase()));
    } else {
      await setCidadeCliente("");
    }
  };

  const setLogradouro = async (cep) => {
    const { loadingDisponibilidade } = props;
    const formValues = formRef.current.getData();

    const dados = {
      cep: Number(formValues.endereco?.cep?.replace(/\D/g, "")),
    };

    if (!cep) return;

    if (Number(cep) === Number(formValues.endereco?.cep) || cep?.replace(/\D/g, "").length < 8) {
      return openSnackbar(messages.CEP_INVALIDO);
    }

    if (!loadingDisponibilidade) {
      try {
        setDisabled(true);

        const { data } = await Api.getEndereco(dados.cep);

        await handleCidadeCliente(data);

        setRua(data);
      } catch (err) {
        const { response } = err;

        openSnackbar(response?.data?.msg || messages.CIDADE_NAO_ENCONTRADA);
      } finally {
        setDisabled(false);
      }
    }
  };

  const handleChangeRecaptcha = () => {
    if (recaptchaRef.current.getValue()) {
      setRecaptcha({ verify: true });
    } else {
      setRecaptcha({ verify: false, error: messages.RECAPTCHA_ERROR });
    }
  };

  const handleRecaptchaExpires = () => {
    recaptchaRef.current.reset();
    setRecaptcha({ verify: false });
  };

  const renderDrawer = () => {
    const { params } = props;

    const Content = () => (
      <DrawerContainer $isClienteNova={isClienteNova}>
        {isClienteNova ? (
          <Wrapper>
            <CardBox>
              <span
                style={{
                  color: "#ff6f00",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                Prezado(a) cliente,
              </span>
              <p
                style={{
                  color: "#727272",
                  fontSize: "20px",
                }}
              >
                Temos uma ótima notícia! A Ligga já está disponível para seu endereço!
              </p>
              <p
                style={{
                  color: "#727272",
                  fontSize: "20px",
                }}
              >
                Para continuar com a contratação, entre em contato via WhatsApp!
              </p>
              <p
                style={{
                  color: "#727272",
                  fontSize: "20px",
                }}
              >
                Clique no botão abaixo e fale com um de nossos consultores.
              </p>
              <ButtonWhats
                onClick={() => {
                  window.open(
                    "https://api.whatsapp.com/send?phone=+5541998138739&text=Quero+contratar+os+servi%C3%A7os+da+Ligga%21",
                    "_blank"
                  );
                }}
              >
                <SvaImage src={WhatsApp} alt="whats" />
                Fale conosco!
              </ButtonWhats>
            </CardBox>
          </Wrapper>
        ) : (
          <>
            <GridItem>
              <span
                style={{
                  color: "#ff6f00",
                  fontSize: "22px",
                  fontWeight: "bolder",
                }}
              >
                {params?.id ? messages.PLANO_INDISPONIVEL : messages.SELECIONE_PLANO}
              </span>
            </GridItem>
            <GridItem lg={10}>
              <p
                style={{
                  color: "#727272",
                  lineHeight: "30px",
                  margin: "15px 0px 30px 0px",
                  fontSize: "20px",
                }}
              >
                {params?.id && messages.PLANO_INDISPONIVEL_CEP}
              </p>
            </GridItem>
            <div className="list">
              {Array.isArray(produtos) && produtos.length ? (
                produtos.map((item, index) => {
                  const { setDataProducts } = props;

                  return <Card item={item} index={index} onClick={setDataProducts} />;
                })
              ) : (
                <div>{messages.PLANO_INDISPONIVEL_CIDADE}</div>
              )}
            </div>
          </>
        )}
      </DrawerContainer>
    );

    return (
      <Drawer
        anchor={isMobile ? "bottom" : "right"}
        open={drawOpen}
        onClose={handleCloseDrawer}
        sx={{ "&.MuiDrawer-root": { zIndex: "99999" } }}
      >
        <Content />
      </Drawer>
    );
  };

  const renderInputMaps = () => {
    return (
      <MapsInputContainer>
        <MapsInput handleChangePlaces={handleChangePlaces} />
      </MapsInputContainer>
    );
  };

  return (
    <UnForm
      className="titleInitial"
      ref={formRef}
      onSubmit={(data) => {
        const { endereco, ...rest } = data;
        handleSubmit({
          ...rest,
          endereco: {
            ...endereco,
            ...logradouro[0],
          },
        });
      }}
    >
      <Snackbar
        anchorOrigin={SNACKBAR_OPTIONS.orientation}
        open={snackbar.open}
        sx={SNACKBAR_OPTIONS.style}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbar.message}
      />

      <GridContainer>
        <GridItem xs={12}>
          <BigTitle gutterBottom={18}>Dados Pessoais</BigTitle>
        </GridItem>

        <GridItem xs={12} md={4} sm={12}>
          <XInput name="socialname" title="Como deseja ser chamado?" required />
        </GridItem>
        <GridItem xs={12} md={4}>
          <XInputMask
            mask="(99) 99999-9999"
            name="phonenumber"
            title="Celular"
            placeholder="(__) _____-____"
            required
          />
        </GridItem>
        <GridItem xs={12} md={4} sm={12} lg={4} xl={4}>
          <XInput name="email" type="email" title="E-mail" required />
        </GridItem>

        <GridItem xs={12}>
          <BigTitle gutter="15px 0px 0px">Endereço</BigTitle>
        </GridItem>

        {/* <GridItem xs={12} md={12}>
          {
            <XRadioGroup
              name="endereço"
              defaultValue="cep"
              onChange={setEndereco}
              items={[
                { value: "cep", label: "Buscar pelo CEP" },
                { value: "maps", label: "Buscar pelo Mapa" },
              ]}
            />
          }
        </GridItem> */}

        {endereco === "cep" ? (
          <Scope path="endereco">
            <GridItem xs={12} md={3}>
              <XInputMask
                mask="99999-999"
                name="cep"
                title="CEP"
                maskPlaceholder="_"
                placeholder="_____-___"
                onBlur={setLogradouro}
                disabled={disabled}
                required
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <GoogleAutoComplete
                name="logradouro"
                title="Endereço"
                disabled={disabled}
                value={logradouro || []}
                loading={disabled}
                setRua={setRua}
                required
              />
            </GridItem>
            <GridItem xs={12} md={3}>
              <XInputMask mask="99999" name="number" title="Número" required />
            </GridItem>
          </Scope>
        ) : (
          <>
            <GridItem xs={12} sm={12} md={12} xl={12} lg={12}>
              <Maps handleChangeCoordinates={handleChangeCoordinates} data={maps} inputWillRendered={renderInputMaps} />
            </GridItem>

            <Scope path="endereco">
              <GridItem xs={12} sm={12} md={12} xl={12} lg={12}>
                <XInputMask mask="99999" name="number" title="Confirme o número" required />
              </GridItem>
            </Scope>
          </>
        )}

        {renderDrawer()}

        <GridItem lg={12}>
          <label>
            <Checkbox pointer checked={acceptnewsletter} onChange={(e) => setAcceptNewsletter(e.target.checked)} />
            <span
              style={{
                color: "#727272",
                marginLeft: "10px",
                lineHeight: "10px",
                userSelect: "none",
              }}
            >
              Ao preencher os campos desse carrinho de compras autorizo a Ligga a entrar em contato via e-mail, SMS,
              WhatsApp ou ligação.
            </span>
          </label>
        </GridItem>

        <ActionForm isMobile={isMobile} recaptchaError={recaptcha.error}>
          <GridItem>
            <div className="recaptcha">
              <RecaptchaBox recaptchaError={recaptcha.error}>
                <Recaptcha
                  sitekey={RECAPTCHA_KEY}
                  ref={recaptchaRef}
                  onChange={handleChangeRecaptcha}
                  onExpired={handleRecaptchaExpires}
                  onError={handleRecaptchaExpires}
                />
              </RecaptchaBox>

              {recaptcha.error && <RecaptchaError>{recaptcha.error}</RecaptchaError>}
            </div>
          </GridItem>

          <CustomButton
            id="informacoes"
            type="submit"
            disabled={disabled || loadingDisponibilidade}
            loading={loadingDisponibilidade}
            isMobile={isMobile}
            label="Verificar Disponibilidade"
          />
        </ActionForm>
      </GridContainer>
    </UnForm>
  );
}

export default Form;
