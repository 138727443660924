import React, { useState } from "react";

import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

import {
  XLoading,
  XMapsInput,
  XSuggestion,
  XSuggestionContainer,
} from "./styles";

export default function MapsInput({ ...props }) {
  const [address, setAddress] = useState("");

  const { handleChangePlaces } = props;

  const searchOptions = {
    language: "pt-BR",
    types: ["address"],
  };

  const handleChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleSelect = async (newAddress) => {
    let coordinates;

    setAddress(newAddress);

    try {
      const response = await geocodeByAddress(newAddress);
      coordinates = {
        lat: response.at()?.geometry?.location?.lat(),
        lng: response.at()?.geometry?.location?.lng(),
      };

      const obj = {
        lat: coordinates.lat,
        lng: coordinates.lng,
        logradouro: newAddress,
      };

      handleChangePlaces(obj);
    } catch (err) {
      handleChangePlaces({});
    }
  };

  const renderOptions = ({ ...args }) => {
    const { getInputProps, suggestions, getSuggestionItemProps, loading } =
      args;

    const filteredOptions = suggestions.filter(
      (item) =>
        item.terms?.at(-1)?.value === "Brasil" ||
        item.terms?.at(-1)?.value === "Brazil"
    );

    return (
      <div>
        <XMapsInput
          {...getInputProps({
            placeholder: "Pesquisar",
            className: "location-search-input",
          })}
          value={address}
        />
        <XSuggestionContainer enderecos={filteredOptions}>
          {loading && <XLoading>Pesquisando...</XLoading>}
          {filteredOptions.map((suggestion) => {
            const className = suggestion.active
              ? "suggestion-item-active"
              : "suggestion-item";

            return (
              <XSuggestion
                {...getSuggestionItemProps(suggestion, { className })}
              >
                <span>{suggestion.description}</span>
              </XSuggestion>
            );
          })}
        </XSuggestionContainer>
      </div>
    );
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
      debounce={600}
    >
      {(args) => {
        return renderOptions(args);
      }}
    </PlacesAutocomplete>
  );
}
