import React from "react";

import {
  Bold,
  CardContainer,
  ColorizePrice,
  DisplayAdd,
  DisplayContainer,
  DisplayNumber,
  DisplayRemove,
  DisplayExtraFields,
  InformacoesContainer,
  ProdutoNameTached,
  RadioButton,
  SelectContainer,
  TittleOpcional,
} from "./styles";
import { Insurance } from "../Insurance";

export default function DrawerCard({
  ativo,
  produto,
  opcional,
  opcionaisSelecionados,
  setOpcionaisSelecionados,
  setQuantidadeMesh,
  quantidadeMesh,
  infoSeguro,
  onChangeInfoSeguro
}) {
  const filteredState = (selecionados, item) => {
    const isSelectedItem = !selecionados
      .map((selectedItem) => selectedItem.id)
      .includes(item.id);

    if (isSelectedItem) {
      return [...selecionados, item];
    } else {
      return [
        ...selecionados.filter((selectedItem) => selectedItem.id !== item.id),
      ];
    }
  };

  const handleAddMesh = () => {
    setQuantidadeMesh((old) => old + 1);
  };

  const handleRemoveMesh = () => {
    setQuantidadeMesh((old) => (old > 1 ? old - 1 : 1));
  };

  const handleChangeOpcional = (item) => {
    setOpcionaisSelecionados((old) => filteredState(old, item));
  };

  return (
    <CardContainer ativo={ativo}>
      <SelectContainer onClick={() => handleChangeOpcional(opcional)}>
        <RadioButton ativo={ativo} />
      </SelectContainer>
      <InformacoesContainer>
        <TittleOpcional>
          {opcional?.urlImg && !opcional?.urlImg?.includes("teste2") ? (
            <img src={opcional.urlImg} alt="productImage" />
          ) : (
            <ProdutoNameTached>{opcional?.descricao}</ProdutoNameTached>
          )}
        </TittleOpcional>
        O melhor do <Bold>{opcional.descricao} </Bold>
        por{" "}
        <ColorizePrice>
          R$
          {opcional.valor.toFixed(2).replace?.(".", ",")}
        </ColorizePrice>
        {opcionaisSelecionados.find((item) => item.descricao.includes("MESH"))
          ?.id && opcional?.descricao.includes("MESH") ? (
          <DisplayContainer>
            <DisplayRemove
              onClick={handleRemoveMesh}
              disabled={quantidadeMesh === 1}
            >
              -
            </DisplayRemove>
            <DisplayNumber>{quantidadeMesh}</DisplayNumber>
            <DisplayAdd
              onClick={handleAddMesh}
              disabled={quantidadeMesh >= (opcional?.qtdeMax || 3)}
            >
              +
            </DisplayAdd>
          </DisplayContainer>
        ) : null}
        {opcionaisSelecionados.find((item) => item.descricao.includes("SEGURO"))
          ?.id && opcional?.descricao.includes("SEGURO") ? (
          <DisplayExtraFields>
            <Insurance info={infoSeguro} onChange={onChangeInfoSeguro} />
          </DisplayExtraFields>
        ) : null}
      </InformacoesContainer>
    </CardContainer>
  );
}
