import styled from "styled-components";

export const Divisor = styled.div`
  margin: 20px auto 20px auto;
  border: 1px solid #e0e0e0;
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: orange;
  stroke-width: 3px;
  color: orange;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Label = styled.span`
  color: #fff;
  margin-left: 10px;
  line-height: 10px;
  user-select: none;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid ${(props) => (props.error ? "#f00" : "#FAA61A")};
  cursor: ${(props) => (props.pointer ? "pointer" : "auto")};

  ${HiddenCheckbox}:checked + & {
    box-shadow: 0 0 0 1px orange;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    color: orange;
  }
`;
