import { CircularProgress } from "@mui/material";
import styled, { css } from "styled-components";

export const ButtonCustom = styled.button`
  box-shadow: none;
  font-size: 16px;
  font-weight: bold;
  color: #ffff;
  padding: 6px 20px;
  line-height: 1.5;
  background: rgb(101,174,84);
  background: linear-gradient(172deg, rgba(101,174,84,1) 0%, rgba(177,193,77,1) 100%);
  border-radius: 8px;
  min-height: 52px;
  min-width: 150px;
  position: relative;
  border: none;

  ${({ loading }) =>
    loading
      ? css`
          .progressLabel {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            height: 100%;
            width: 100%;
            left: 0;
          }
        `
      : css`
          .progressLabel {
            opacity: 0;
            position: absolute;
          }
        `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 15px;
    `}

  &:focus {
    box-shadow: 0 0 0.5rem #ff6f00;
  }

  &:disabled {
    background-color: #eceff1;
    color: #455a64;
    opacity: 25%;
  }
`;

export const Progress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    color: #ff6f00;
  }
`;
