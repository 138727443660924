import { InputBase, Select } from "@mui/material";
import styled from "styled-components";

export const CustomInputSelect = styled(InputBase)`
  label + & {
    margin: -3px;
  }

  border-radius: 4px;
  position: relative;
  font-size: 16px;
  border: 1px solid #cfd8dc;
  padding: 10px 20px 10px 12px;

  .MuiSvgIcon-root {
    color: #fff;
  }

  select {
    :focus {
      background-color: #fff;
    }
  }

  &:hover {
    border-color: #cfd8dc;
  }

  &.Mui-focused {
    border-radius: 4px;
    transition: ease-in-out 0.1s;
    border: 2px solid #ff6f00;
  }

  .MuiSelect-select {
    color: #fff;
  }

  &.Mui-disabled {
    background-color: #3e4041;
    border-color: #ebeced;

    .MuiSelect-select {
      -webkit-text-fill-color: #ebeced;
      cursor: not-allowed;
    }

    :hover {
      border-color: #f5f7f8;
    }

    :disabled {
      color: #fff;
    }

    .MuiSelect-icon {
      color: #fff;
    }
  }
`;

export const CustomSelect = styled(Select)`
  height: 56px;

  .Mui-error {
    margin-left: 0;
  }

  .MuiSelect-outlined {
    border-radius: 5px;
    position: relative;
    border-color: ${(props) => (props.error ? "#f00" : "#cfd8dc")};
    font-size: 16px;
    overflow: hidden;
  }
`;
