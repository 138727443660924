import { Component } from "react";

import Geocode from "react-geocode";
import { geocodeByAddress } from "react-places-autocomplete";
class GoogleGeocode extends Component {
  constructor(props) {
    super(props);
    Geocode.setLanguage("pt");
    Geocode.setRegion("br");

    this.state = {
      fullAddress: { logradouro: "", numero: "", cep: "" },
    };
  }
  #KEY = "AIzaSyCI2X-9O0vmEWu3FEMsGByaEYquTYfahB8";

  getCoordinatesByCep = async (cep, cardial) => {
    try {
      if (!cep) {
        return {};
      }
      const result = await geocodeByAddress(cep);

      const location = result.at()?.geometry?.location;

      const coordinates = {
        lat: location?.lat(),
        lng: location?.lng(),
      };

      return cardial === "lat" ? coordinates.lat : coordinates.lng;
    } catch (err) {
      return {};
    }
  };

  getAddressByText = async (address) => {
    let cidade, cep, coordinates, estado, bairro, logradouro, pais, number;

    try {
      const response = await geocodeByAddress(address);

      const location = response.at()?.geometry?.location;

      coordinates = {
        lat: location?.lat(),
        lng: location?.lng(),
      };

      for (const element of response.at().address_components) {
        for (let [index] of element.types?.entries()) {
          switch (element.types[index]) {
            case "route":
              logradouro = element.long_name;
              break;
            case "postal_code":
              cep = element.long_name?.replace(/\D/g, "");
              break;
            case "locality":
              cidade = element.long_name;
              break;
            case "sublocality":
              bairro = element.long_name;
              break;
            case "administrative_area_level_2":
              cidade = element.long_name;
              break;
            case "administrative_area_level_1":
              estado = element.long_name;
              break;
            case "country":
              pais = element.long_name;
              break;
            case "street_number":
              number = element.long_name;
              break;
            default:
          }
        }
      }

      return {
        cidade,
        estado,
        bairro,
        logradouro,
        pais,
        cep,
        number,
        lat: coordinates.lat,
        lng: coordinates.lng,
      };
    } catch (err) {
      if (coordinates.lat && coordinates.lng) {
        return coordinates;
      }
    }
  };

  getAddressByCoordinates = async ({ lat, lng }) => {
    let cidade, cep, estado, bairro, logradouro, pais, number, outher;

    try {
      const response = await Geocode.fromLatLng(lat, lng, this.#KEY);

      for (const element of response.results.at().address_components) {
        for (let [index] of element.types?.entries()) {
          switch (element.types[index]) {
            case "route":
              logradouro = element.long_name;
              break;
            case "postal_code":
              cep = element.long_name?.replace(/\D/g, "");
              break;
            case "locality":
              cidade = element.long_name;
              break;
            case "sublocality":
              bairro = element.long_name;
              break;
            case "administrative_area_level_2":
              cidade = element.long_name;
              break;
            case "administrative_area_level_1":
              estado = element.long_name;
              break;
            case "country":
              pais = element.long_name;
              break;
            case "street_number":
              number = element.long_name;
              break;
            default:
              outher = { [element.long_name]: element.long_name };
          }
        }
      }

      return {
        bairro,
        cep,
        cidade,
        estado,
        lat,
        logradouro,
        lng,
        pais,
        number,
      };
    } catch (err) {
      return { outher } || {};
    }
  };
}

const googleGeocode = new GoogleGeocode();
export default googleGeocode;
