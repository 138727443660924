import styled from "styled-components";

export const Banner = styled.div`
  display: flex;
  background-color: #ff6f00;
  height: 100px;
  border-radius: 3px 3px 0px 0px;
  justify-content: center;

  img {
    height: 100px;
    width: 120px;
    opacity: 10%;
  }

  @media (max-width: 900px) {
    img {
      display: none;
    }

    @media (max-width: 800px) {
      img {
        display: block;
      }
    }
  }

  @media (max-width: 350px) {
    img {
      display: none;
    }
  }
`;

export const ColorizedText = styled.span`
  color: ${({ color }) => color};
  font-weight: bold;
`;

export const ContainerProduto = styled.div``;

export const DescritionContainer = styled.div`
  margin: 25px;
  color: #455a64;
`;

export const ImageContainer = styled.div``;

export const Item = styled.li`
  color: #fff;
`;

export const ListItem = styled.ul``;

export const Paper = styled.div`
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  height: auto;
`;

export const Price = styled.span`
  font-size: 30px;
  font-weight: bold;
  color: white;
`;

export const SvaContainer = styled.div`
  display: flex;
  justify-content: left;
`;

export const SvaImage = styled.img`
  display: table;
  width: 38px;
  height: 38px;
  margin-top: 10px;
`;

export const SvaImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
`;

export const SvaName = styled.div`
  color: #ebeced;
  font-size: 7px;
  margin-top: 5px;
`;

export const TaxaInstalacao = styled.div`
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
`;

export const TotalPriceContainer = styled.div`
  height: 70px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const TotalPrice = styled.div`
  background: rgb(101, 174, 84);
  background: linear-gradient(
    180deg,
    rgba(101, 174, 84, 1) 0%,
    rgba(177, 193, 77, 1) 100%
  );
  height: 100%;
  width: 100%;
  min-width: 150px;
  border-radius: 7px 7px 0px 0px;
`;

export const TotalPriceLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px auto;
`;

export const TotalPriceLabel = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
`;

export const TotalPriceLabelBold = styled.span`
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  margin: 0px 3px;
`;

export const TotalPriceLabelInerit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 200;
  color: #fff;
  font-size: 17px;
`;

export const TitleContainer = styled.div`
  text-align: center;
`;

export const TitleSva = styled.div``;

export const Text = styled.div`
  padding: 20px 0px 20px 10px;
  color: ${(props) => props.color};
`;

export const WeightText = styled.span`
  font-weight: bold;
  color: #fff;
`;
