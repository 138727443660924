import React from "react";

import { FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import { XRadio } from "./styles";

export default function XRadioGroup({ ...props }) {
  const { controlProps, defaultValue, items, name, radioProps, onChange } = props;

  const isValidItems = () => Array.isArray(items) && items.length;

  return isValidItems() ? (
    <FormControl>
      <RadioGroup
        {...radioProps}
        row
        name={name}
        onChange={(e) => onChange(e.target.value)}
        defaultValue={defaultValue}
        key={name}
      >
        {items.map((item, index) => (
          <FormControlLabel {...controlProps} value={item.value} control={<XRadio />} label={item.label} key={index} />
        ))}
      </RadioGroup>
    </FormControl>
  ) : null;
}
