import styled from "styled-components";

export const Container = styled.div`
  margin-top: 30px;
  background-color: #2B2B2B;
  padding: 30px;
  border-radius: 10px;
  h2 {
    font-size: 28px;
    color: ${({ theme: { colors } }) => colors.stepper.title};
    padding-bottom: 10px;
    font-weight: 700;
    &::before {
      content: "";
      width: 100%;
      height: 5px;
      background-color: red;
    }
  }
`;

export const Separator = styled.div`
  border-bottom: 3px solid ${({ theme: { colors } }) => colors.stepper.title};
  margin-bottom: 20px;
  width: 80px;
`;
