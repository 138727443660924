import React from "react";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import { FormControl, InputLabel } from "@mui/material";

const CustomTextField = styled(TextField)`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px #1a1a1a inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input:disabled {
    -webkit-text-fill-color: #fff;
  }

  label + & {
    margin-top: 15px;
  }

  .Mui-error {
    margin-left: 0;
  }

  .MuiInputBase-input {
    color: #fff;
  }

  .MuiOutlinedInput-root {
    border-radius: 4px;
    position: relative;
    border-color: ${(props) => (props.error ? "#f00" : "#cfd8dc")};
    font-size: 16px;
    overflow: hidden;
    color: #fff;
    height: 65px;

    fieldset {
      top: 0;
      height: 65px;
      border-color: #727272;
    }

    :hover fieldset {
      border-color: #cfd8dc;
    }

    &.Mui-disabled fieldset {
      background-color: #cfd8dc;
      opacity: 30%;
    }

    :disabled {
      color: #fff;
    }

    &.Mui-focused fieldset {
      transition: ease-in-out 0.1s;
      border: 2px solid #ff6f00;
    }
  }
`;

export default function XTextInput({ id, length, title, required, hidden, ...props }) {
  return (
    <FormControl variant="standard" sx={{ ...(hidden && { height: "0px" }) }} fullWidth>
      <InputLabel
        sx={{
          fontWeight: "bold",
          color: "#fff",
          fontSize: "16px",
          "&.Mui-focused": {
            color: "#ff6f00",
          },
        }}
        shrink
        htmlFor={id || "XTextField"}
        required={required}
      >
        {title}
      </InputLabel>
      <CustomTextField
        id={id || "XTextField"}
        InputProps={{ disableunderline: "true" }}
        inputProps={{ maxLength: length || 255 }}
        type={hidden ? "hidden" : ""}
        {...props}
      />
    </FormControl>
  );
}
