import React from "react";

import { ButtonCustom, Progress } from "./styles";

export default function CustomButton({ ...props }) {
  const { id, disabled, isMobile, label, loading, type } = props;

  return (
    <ButtonCustom
      {...props}
      id={id}
      type={type || "submit"}
      disabled={loading || disabled}
      isMobile={isMobile}
      loading={loading}
    >
      {label}
      {loading ? <div className="progressLabel">{<Progress />}</div> : null}
    </ButtonCustom>
  );
}
