import { useLocation, useNavigate, useParams } from "react-router-dom";

import MainScreen from "./pages";

function App({ themeAssets }) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  return (
    <MainScreen
      navigate={navigate}
      location={location}
      params={params}
      themeAssets={themeAssets}
    />
  );
}

export default App;
