import React from "react";
import ReactDOM from "react-dom/client";

// React Router
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Styled Components
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles/globalStyles";
import { theme } from "./styles/theme";

// Google Tag Manager
import TagManager from "react-gtm-module";

// HotJar
import HotJar from "./components/HotJar";

// Components
import App from "./App";

const tagManagerArgs = {
  gtmId: "GTM-KQTWM4F",
};

TagManager.initialize(tagManagerArgs);
HotJar.initial();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes>
        <Route path="/" index element={<App themeAssets={theme.assets} />} />
        <Route path="/:id" index element={<App themeAssets={theme.assets} />} />
      </Routes>
    </ThemeProvider>
  </BrowserRouter>
);
