import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

function GridContainer({ spacing, direction, children, ...rest }) {
  return (
    <Grid container direction={direction} spacing={spacing} {...rest}>
      {children}
    </Grid>
  );
}

GridContainer.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.string,
};

GridContainer.defaultProps = {
  spacing: 2,
  direction: "row",
};

export default GridContainer;
