import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

import GridContainer from "../GridContainer";
import GridItem from "../GridItem";

class Maps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialCenter: { lat: -25.448557, lng: -49.297362 },
    };
  }

  marker = () => {
    const { data } = this.props;

    return <Marker position={data} />;
  };

  render() {
    const { google, handleChangeCoordinates, inputWillRendered, data } =
      this.props;
    const { initialCenter } = this.state;

    const zoomCoordinates = data.lat && data.lng ? 16 : 11;

    const mapStyles = {
      position: "relative",
      width: "auto",
      height: "500px",
    };

    return (
      <GridContainer>
        <GridItem lg={12} xs={12} sm={12} xl={12} md={12}>
          <Map
            google={google}
            zoom={zoomCoordinates}
            streetViewControl={false}
            mapTypeControl={false}
            containerStyle={mapStyles}
            center={data}
            initialCenter={initialCenter}
            onClick={handleChangeCoordinates}
          >
            {inputWillRendered()}

            {this.marker()}
          </Map>
        </GridItem>
      </GridContainer>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAPS,
})(Maps);
