import styled from "styled-components";

const StepsContainer = styled.section`
  padding: 40px 20px 20px 20px;
  @media (max-width: 800px) {
    padding: 50px; 
  }
  @media (min-width: 1000px) {
    padding: 60px;
  }
  @media (min-width: 1200px) {
    padding: 80px;
  }
`;

export default StepsContainer;
