import React, { useRef, useEffect } from "react";
import ReactInputMask from "react-input-mask";
import { useField } from "@unform/core";

import XInput from "./input";

export default function XInputMask({
  name,
  mask,
  onChange,
  onBlur,
  disabled,
  maskPlaceholder,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue("");
      },
    });
  }, [fieldName, registerField]);

  return (
    <ReactInputMask
      ref={inputRef}
      maskChar=" "
      defaultValue={defaultValue}
      error={error}
      mask={mask}
      disabled={disabled}
      maskPlaceholder={maskPlaceholder}
      onChange={(e) => {
        if (onChange) {
          return onChange(e.target.value);
        }
      }}
      onBlur={(e) => {
        if (onBlur) {
          return onBlur(e.target.value);
        }
      }}
    >
      {() => <XInput name={name} disabled={disabled} {...rest} />}
    </ReactInputMask>
  );
}
