import styled from "styled-components";

export const XMapsInput = styled.input`
  height: 58px;
  font-size: 17px;
  width: auto;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.23);
  padding: 0 8px;
  border-radius: 5px;

  :focus {
    outline-color: #ff6f00;
  }

  :hover {
    border: 1px solid #cfd8dc;
  }
`;

export const XLoading = styled.div`
  background-color: #fff;
  border-width: 1px 2px 2px 2px;
  border-color: #ff6f00;
  border-style: solid;
`;

export const XSuggestionContainer = styled.div`
  background-color: #fff;

  ${(props) =>
    props.enderecos?.length &&
    `
  border-width: 1px 2px 2px 2px;
  border-color: #ff6f00;
  border-style: solid;
  `}
`;

export const XSuggestion = styled.div`
  background-color: #fff;
  padding: 5px 5px;
  cursor: pointer;

  transition: background-color 0.3s, color 0.3s;

  &.suggestion-item-active {
    background-color: #f0f0f0;
    color: #ff6f00;
  }
`;
