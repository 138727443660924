import React from "react";
import { Form as UnForm } from "@unform/web";
import { Grid, Snackbar } from "@mui/material";
import dayjs from "dayjs";

import GridContainer from "../../../components/GridContainer";
import GridItem from "../../../components/GridItem";
import XInput from "../../../components/Form/input";
import CustomButton from "../../../components/CustomButton";
import ProductBanner from "../../../components/ProductBanner";
import { Info } from "../Faturamento/styles";
import Checkbox from "../../../components/Checkbox";

import {
  AlterarButton,
  CheckboxContent,
  DadosInstalacaoContainer,
  DadosInstalacaoInfos,
  DadosPessoaisContainer,
  DestaqueTitle,
  Divisor,
  FidelidadeContainer,
  FidelidadeContent,
  InformacoesFaturaContainer,
  LinkLabel,
  LinkSaibaMais,
  SpanLabel,
  SpanLinkSaibaMais,
  Title,
  XDisplayLabel,
  XDisplayTitle,
} from "./styles";

import { ACEITAR_TERMOS, PRESTACAO_SERVICO, SUMARIO_CONTRATO, TERMO_FIDELIZACAO } from "../../../shared/message";

import { CONTRATO_COPEL_FIBRA, OFERTA_PROMOCIONAL, SUMARIO_CONTRATO_HREF } from "../../../shared/links";
import XInputMask from "../../../components/Form/markedInput";

import { formatCellPhone } from "../../../utils/string";

const styles = {
  button: { display: "flex", justifyContent: "flex-end" },
  dadosPessoais: {
    alterarDados: {
      display: "flex",
      flexDirection: "column",
      margin: "auto 0px 0px 0px",
    },
    container: {
      marginLeft: "30px",
      lineHeight: "20px",
    },
    infos: {
      margin: "0px 30px",
      lineHeight: "20px",
    },
  },
  dadosInstalacao: {
    aviso: {
      lineHeight: "20px",
    },
    avisoData: {
      background: "#C43E00",
      display: "flex",
      padding: "5px 5px 25px 5px",
      borderRadius: "5px",
      margin: "0",
    },
    container: {
      margin: "0px 30px 0px",
      lineHeight: "20px",
    },
  },
  informacoesFatura: {
    container: {
      marginLeft: "30px",
      lineHeight: "20px",
    },
  },
  snackbar: {
    anchor: {
      vertical: "bottom",
      horizontal: "center",
    },
    style: {
      ".MuiSnackbarContent-root": {
        background: "#E53935",
        color: "#fff",
        fontSize: "16px",
      },
    },
  },
};

const TIPO_RESIDENCIA = {
  1: "Casa/Sobrado",
  2: "Edifício",
  3: "Condominío",
  4: "Sala",
};

const FORMATO_COBRANCA = {
  0: "Digital",
  1: "Impresso",
};

function XDisplayInfo({ title, label, gridOptions }) {
  return (
    <GridItem {...(gridOptions || {})}>
      <XDisplayTitle>{title}</XDisplayTitle>
      <br />
      <XDisplayLabel>{label}</XDisplayLabel>
    </GridItem>
  );
}

function Form({
  alterarLoading,
  form,
  formRef,
  handleAlterar,
  handleCaptureData,
  handleSubmit,
  loading,
  produto,
  pagamento,
  ...props
}) {
  const [alterarDados, setAlterarDados] = props.alterarDados;
  const [snackbar, closeSnackbar] = props.snackbar;
  const [acceptTerms, setAcceptTerms] = props.acceptTerms;

  const renderDadosPessoais = () => {
    return (
      <DadosPessoaisContainer>
        <GridContainer
          spacing={{ sm: 2, md: 3, xs: 6 }}
          columns={{ xs: 4, sm: 12, ms: 12 }}
          direction="row"
          alignItems="baseline"
          sx={styles.dadosPessoais.container}
        >
          <XDisplayInfo gridOptions={{ xs: 6 }} title="Nome Completo" label={form.fullname} />

          <XDisplayInfo gridOptions={{ xs: 6 }} title="CPF" label={form.cpf} />

          <XDisplayInfo
            gridOptions={{ xs: 6 }}
            title="Data de Nascimento"
            label={dayjs(form.birthdate).format("DD/MM/YYYY")}
          />

          <XDisplayInfo title="Nome Completo da Mãe" gridOptions={{ xs: 6 }} label={form.mother} />
        </GridContainer>

        <Divisor />

        <GridContainer
          spacing={{ sm: 2, md: 3, xs: 6 }}
          columns={{ xs: 4, sm: 12, ms: 12 }}
          direction="row"
          alignItems="baseline"
          sx={styles.dadosPessoais.infos}
        >
          <XDisplayInfo gridOptions={{ xs: 4 }} title="Celular" label={formatCellPhone(form.phonenumber)} />

          <XDisplayInfo gridOptions={{ xs: 4 }} title="E-mail" label={form.email} />

          <Grid item xs={4}>
            <AlterarButton
              hidden={alterarDados || loading}
              onClick={() => setAlterarDados(true)}
              disabled={loading || alterarLoading}
            >
              ALTERAR
            </AlterarButton>
          </Grid>
          {alterarDados && (
            <>
              <GridItem xs={4}>
                <XInputMask
                  mask="(99) 99999-9999"
                  name="phonenumber"
                  title="Celular"
                  placeholder="(__) _____-____"
                  required
                />
              </GridItem>
              <GridItem xs={4}>
                <XInput name="email" length={50} title="E-mail" required />
              </GridItem>
              <GridItem xs={4} sx={styles.dadosPessoais.alterarDados}>
                <CustomButton
                  id="alterarDados"
                  type="button"
                  disabled={loading || alterarLoading}
                  loading={alterarLoading}
                  onClick={handleAlterar}
                  label="ALTERAR"
                />
              </GridItem>
            </>
          )}
        </GridContainer>
      </DadosPessoaisContainer>
    );
  };

  const renderDadosInstacao = () => {
    return (
      <DadosInstalacaoContainer>
        <GridContainer
          spacing={{ sm: 0, md: 0, xs: 0 }}
          columns={{ xs: 0, sm: 0, ms: 0 }}
          sx={styles.dadosInstalacao.aviso}
        >
          <GridContainer xs={12} sx={styles.dadosInstalacao.avisoData}>
            <GridItem xs={12}>
              <DestaqueTitle size={22}>ATENÇÃO!</DestaqueTitle>

              <DestaqueTitle top={5}>Lembre-se de estar no local de instalação na data abaixo:</DestaqueTitle>
            </GridItem>

            <GridItem xs={12}>
              <DestaqueTitle size={20}>{form.faturamento.dataInstalacao}</DestaqueTitle>
            </GridItem>
          </GridContainer>
        </GridContainer>

        <DadosInstalacaoInfos>
          <GridContainer
            spacing={{ sm: 2, md: 3, xs: 6 }}
            columns={{ xs: 4, sm: 12, ms: 12 }}
            direction="row"
            alignItems="baseline"
            sx={styles.dadosInstalacao.container}
          >
            <XDisplayInfo gridOptions={{ xs: 6 }} title="Endereço de Instalação" label={form.endereco.logradouro} />

            <XDisplayInfo gridOptions={{ xs: 6 }} title="Número" label={form.endereco.number} />

            <XDisplayInfo
              gridOptions={{ xs: 6 }}
              title="Tipo do Imóvel"
              label={TIPO_RESIDENCIA[form.tipoImovel] || "N/A"}
            />

            <XDisplayInfo gridOptions={{ xs: 6 }} title="Unidade" label={form.unit || form.endereco.number} />

            <XDisplayInfo gridOptions={{ xs: 6 }} title="CEP" label={form.endereco.cep} />

            <XDisplayInfo gridOptions={{ xs: 6 }} title="Complemento" label={form.complement || "Não possui"} />
          </GridContainer>
        </DadosInstalacaoInfos>
      </DadosInstalacaoContainer>
    );
  };

  const renderFidelidade = () => {
    const { faturamento } = form;
    const { parcelamentosSemFidelidade: parcelamento } = produto;

    const getLabel = () => {
      if (faturamento?.haveTaxa) {
        return `Sem Fidelidade - ${faturamento.numParcelas} x R$${Number(
          parcelamento.valorTotal / faturamento.numParcelas
        )
          .toFixed(2)
          .replace?.(".", ",")}`;
      }
      return "Com Fidelidade";
    };

    return (
      <FidelidadeContainer>
        <GridContainer
          spacing={{ sm: 2, md: 4, xs: 6 }}
          columns={{ xs: 4, sm: 12, ms: 12 }}
          sx={styles.informacoesFatura.container}
        >
          <GridItem lg={12}>
            <FidelidadeContent>{getLabel()}</FidelidadeContent>
          </GridItem>
        </GridContainer>
      </FidelidadeContainer>
    );
  };

  const renderInformacoesFatura = () => {
    return (
      <InformacoesFaturaContainer>
        <GridContainer
          spacing={{ sm: 2, md: 3, xs: 6 }}
          columns={{ xs: 4, sm: 12, ms: 12 }}
          sx={styles.informacoesFatura.container}
        >
          <XDisplayInfo
            gridOptions={{ xs: 6 }}
            title="Método de Pagamento"
            label={form.faturamento.banco ? "Débito Em Conta" : "Boleto"}
          />

          <XDisplayInfo
            gridOptions={{ xs: 6 }}
            title="Data de Vencimento"
            label={form.faturamento.vencimento <= 9 ? `0${form.faturamento.vencimento}` : form.faturamento.vencimento}
          />

          <XDisplayInfo
            gridOptions={{ xs: 6 }}
            title="Formato de Cobrança"
            label={FORMATO_COBRANCA[form.faturamento.fatura]}
          />

          <XDisplayInfo
            gridOptions={{ xs: 6 }}
            title="Endereço de Cobrança"
            label={form.faturamento.endereco.logradouro || form.endereco.logradouro}
          />

          <XDisplayInfo
            gridOptions={{ xs: 6 }}
            title="Número"
            label={form.faturamento.endereco.number || form.endereco.number}
          />

          <XDisplayInfo
            gridOptions={{ xs: 6 }}
            title="CEP"
            label={form.faturamento.endereco.cep || form.endereco.cep}
          />

          <XDisplayInfo
            gridOptions={{ xs: 6 }}
            title="Complemento"
            label={form.faturamento.endereco.complement || form.complement || "Não possui"}
          />
        </GridContainer>
      </InformacoesFaturaContainer>
    );
  };

  const renderLabels = (type, label, typeConfigs) => {
    const { error } = props;

    if (type === "span") {
      return <SpanLabel error={error}>{label}</SpanLabel>;
    } else {
      return (
        <LinkLabel target="_blank" rel="noreferrer" {...typeConfigs}>
          {label}
        </LinkLabel>
      );
    }
  };

  const renderCheckboxes = () => {
    const { error } = props;

    return (
      <>
        <CheckboxContent>
          <Checkbox
            pointer
            checked={acceptTerms}
            error={error}
            onChange={(e) => setAcceptTerms(e.target.checked)}
            label={renderLabels("span", ACEITAR_TERMOS)}
          />
        </CheckboxContent>
        <CheckboxContent>
          <Checkbox
            checked={acceptTerms}
            label={renderLabels("link", PRESTACAO_SERVICO, {
              href: CONTRATO_COPEL_FIBRA,
            })}
          />
        </CheckboxContent>

        <CheckboxContent>
          <Checkbox
            checked={acceptTerms}
            label={renderLabels("link", TERMO_FIDELIZACAO, {
              href: OFERTA_PROMOCIONAL,
            })}
          />
        </CheckboxContent>

        <CheckboxContent>
          <Checkbox
            checked={acceptTerms}
            label={renderLabels("link", SUMARIO_CONTRATO, {
              href: SUMARIO_CONTRATO_HREF,
            })}
          />
        </CheckboxContent>
      </>
    );
  };

  const renderLinkSaibaMais = () => (
    <div>
      <SpanLinkSaibaMais>Para mais informações sobre regulamento de oferta de produtos adicionais </SpanLinkSaibaMais>
      <LinkSaibaMais href="https://liggavc.com.br/transparencia/regulamentacao-de-planos-e-ofertas/" target="__blank">
        clique aqui
      </LinkSaibaMais>
      <br></br>
      <SpanLinkSaibaMais>
        Para acessar os Termos de contratação do Seguro Imobiliário Tokio Marine, em caso de contratação,{" "}
      </SpanLinkSaibaMais>
      <LinkSaibaMais
        href="https://www.tokiomarine.com.br/wp-content/uploads/2022/04/guia-servicos-assistencia-completo-residencial-042022v1.pdf"
        target="__blank"
      >
        clique aqui
      </LinkSaibaMais>
    </div>
  );

  return (
    <UnForm className="titleInitial" ref={formRef} onSubmit={handleSubmit}>
      <Snackbar
        anchorOrigin={styles.snackbar.anchor}
        open={snackbar.open}
        sx={styles.snackbar.style}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        message={snackbar.message}
      />

      <GridContainer>
        <GridItem xs={12}>
          <Title>Produto</Title>
        </GridItem>

        <GridItem xs={12} md={12} sm={12} lg={12}>
          <ProductBanner
            taxaInstalacao={form.faturamento?.haveTaxa}
            produto={produto}
            opcionais={form.opcionais}
            pagamento={pagamento}
          />
        </GridItem>

        <GridItem xs={12}>
          <Title>Fidelidade</Title>
        </GridItem>

        <GridItem xs={12}>{renderFidelidade()}</GridItem>

        <GridItem xs={12}>
          <Title>Dados Pessoais</Title>
        </GridItem>

        <GridItem xs={12}>{renderDadosPessoais()}</GridItem>

        <GridItem lg="12">
          <Title top={20}>Dados da Instalação</Title>
        </GridItem>

        <GridItem xs={12}>{renderDadosInstacao()}</GridItem>

        <GridItem xs={12}>
          <Title top={20}>Informações da Fatura</Title>
        </GridItem>

        <GridItem xs={12}>{renderInformacoesFatura()}</GridItem>

        <GridItem xs={12}>{renderCheckboxes()}</GridItem>

        <GridItem xs={12}>{renderLinkSaibaMais()}</GridItem>

        <GridItem xs={12} sx={styles.button}>
          <CustomButton
            id="revisao"
            type="submit"
            disabled={loading || alterarLoading}
            loading={loading}
            label="Contratar minha internet"
          />
        </GridItem>
        <GridItem xs={12}>
          <Info size="12px" color="#cacaca">
            Observações <br /> 1) Impostos já inclusos, exceto em casos de tributação especial. <br />
            2) Ofertas válidas para contratações através do site para pessoa física e jurídica, sujeitas à análise de
            crédito e disponibilidade técnica dos serviços no endereço solicitado. <br />
            3) A velocidade máxima anunciada de acesso e tráfego na internet é nominal, fornecida por cabo, podendo
            sofrer variações decorrentes de fatores externos. <br />
            4) Fidelidade: Permanência mínima de 12 meses com os serviços contratados. Em caso de cancelamento
            antecipado será cobrada multa proporcional ao tempo do contrato. Consulte o Contrato de Prestação de
            Serviços no site da Ligga Telecom. <br />
            5) Mensalidade sujeita ao reajuste após 12 meses, contados da data de contratação do serviço. <br />
            6) Os equipamentos cedidos para a prestação dos serviços são de propriedade da Prestadora e devem ser
            devolvidos em caso de cancelamento. A não devolução dos equipamentos pode gerar custos para o contratante.{" "}
            <br />
            7) A fatura digital e demais documentos estarão disponíveis no www.liggatelecom.com.br acessando Área do
            Cliente. <br />
            8) Serviços Digitais: Serviço Digital XIII– Skeelo & Audiobook, Serviço Digital XIV – Skeelo, Serviço
            Digital XV – Mumo, Serviço Digital XVI – Imagina Só, Serviço Digital XVII – WatchTV & Stingray, Serviço
            Digital XVIII – Molti Educacional, Serviço Digital XIX – Molti Educacional.
          </Info>
        </GridItem>
      </GridContainer>
    </UnForm>
  );
}
export default Form;
