import { axiosPrivate } from "../../../config/axios";
import { pMinDelay } from "../../../utils/promise";

export const saveData = (values, delay = 0) => {
  const response = axiosPrivate.put("/quotation/convertToOrder", values);
  return pMinDelay(response, delay);
};

export const changeContacts = (values, delay = 0) => {
  const response = axiosPrivate.put("/quotation/changeContact", values);
  return pMinDelay(response, delay);
};
