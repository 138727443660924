export const ENDERECO_SITE = "https://liggavc.com.br/";

//Step 4
export const CONTRATO_COPEL_FIBRA =
  "https://liggavc.com.br/wp-content/uploads/2023/05/CONTRATO-DE-PRESTACAO-DE-SERVICO-DE-COMUNICACAO-MULTIMIDIA-SCM.pdf";
export const OFERTA_PROMOCIONAL =
  "https://liggatelecom.com.br/wp-content/uploads/2022/07/2022.06.08-Termo-de-Oferta-de-Beneficio-e-Fidelizacao-OFERTA-PROMOCIONAL-LIGGA-FIBRA-S-JUNHO-CAPITAL.pdf";
export const SUMARIO_CONTRATO_HREF =
  "https://liggavc.com.br/wp-content/uploads/2023/05/CONTRATO-DE-PRESTACAO-DE-SERVICO-DE-COMUNICACAO-MULTIMIDIA-SCM.pdf";

//Conclusao
export const URL_PLAY_STORE = "https://android.liggavc.com.br";
export const URL_APPLE_STORE = "https://apple.liggavc.com.br";
