import { hotjar } from "react-hotjar";

class HotJar {
  #HJID = "143889";
  #HJSV = 6;
  #OPTIONS = {
    identify: "USER_ID",
    properties: {
      userProperty: "value",
    },
  };

  initial = () => {
    hotjar.initialize(this.#HJID, this.#HJSV);
    hotjar.identify(this.#OPTIONS.identify, this.#OPTIONS.properties);
  };
}

const hotJar = new HotJar();
export default hotJar;
