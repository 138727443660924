import React, { useRef, useState } from "react";

import Contato from "./contato";
import Form from "./form";

import * as Api from "./api";
import * as Yup from "yup";

function AvisemeSteps({ ...props }) {
  const formRef = useRef(null);

  const [redirect, setRedirect] = useState(false);
  const [acceptnewsletter, setAcceptNewsLetter] = useState(true);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });

  const handleSubmit = async (data) => {
    const { id, idProduto } = props;

    const dados = {
      ...data,
      phonenumber: data.phonenumber.replace(/\D/g, ""),
      acceptnewsletter,
      id,
      codplano: Number(idProduto),
    };

    try {
      setLoading(true);

      const schema = Yup.object().shape({
        phonenumber: Yup.string()
          .required("Campo Obrigatório")
          .min(10, "Insira um número válido")
          .max(11, "Insira um número válido"),
        email: Yup.string().email("Insira um e-mail válido").required("Campo Obrigatório"),
      });

      await schema.validate(dados, { abortEarly: false });

      const { data: leadResponse } = await Api.gravarLead(dados);

      setRedirect(leadResponse.result);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const { ...errors } = err;
        const errorMessages = {};

        errors.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      } else {
        const {
          response: { data: res },
        } = err;

        setSnackbar({ open: true, message: res.msg });
      }
    } finally {
      setLoading(false);
    }

    try {
      window.dataLayer.push({
        event: "nao_disponivel",
        page_location: "https://vendas.liggavc.com.br",
        currency: "BRL",
        value: props?.produto?.precoProduto,
        items: [
          {
            item_id: Number(props?.idProduto),
            item_name: props?.produto?.nomeProduto,
          },
        ],
      });
    } catch (err) {}
  };

  return (
    <>
      {redirect ? (
        <Contato {...props} setRedirect={setRedirect} />
      ) : (
        <Form
          {...props}
          acceptnewsletter={[acceptnewsletter, setAcceptNewsLetter]}
          formRef={formRef}
          handleSubmit={handleSubmit}
          loading={[loading, setLoading]}
          snackbar={[snackbar, setSnackbar]}
          setRedirect={setRedirect}
        />
      )}
    </>
  );
}

export default AvisemeSteps;
