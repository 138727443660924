import React from "react";

import { Checkbox, FormControl, Grid, InputLabel, MenuItem } from "@mui/material";

import XSelect from "../Select";

import { CheckboxContentSeguro, LinkLabel, SpanLabel } from "../../containers/Steps/Revisao/styles";

import { SpanLinkTermosSeguro, LinkTermosSeguro } from "../Insurance/styles";

import { ACEITAR_TERMOS } from "../../shared/message";

const styles = {
  tipoimovellabel: {
    marginTop: "15px",
    fontWeight: "bold",
    fontSize: "16px",
    position: "relative",
    transform: "translate(0, -1.5px) scale(0.75)",
    "&.Mui-focused": {
      color: "#ff6f00",
    },
  },
};

function Form({ ...props }) {
  const handleChange = (e) => {
    if (props.onChange && typeof props.onChange === "function") {
      let currentInfo = props.info ? props.info[e.target.name] : {};
      if (!currentInfo) currentInfo = {};
      currentInfo = {
        ...currentInfo,
        value: e.target.checked ?? e.target.value,
      };
      props.onChange({ ...props.info, [e.target.name]: { ...currentInfo } });
    }
  };

  const renderLabels = (type, label, typeConfigs) => {
    const { error } = props;

    if (type === "span") {
      return <SpanLabel error={error}>{label}</SpanLabel>;
    } else {
      return (
        <LinkLabel target="_blank" rel="noreferrer" {...typeConfigs}>
          {label}
        </LinkLabel>
      );
    }
  };

  const RenderSeguroInfos = () => {
    return (
      <>
        <Grid item xs={12} md={12} sm={12} lg={6}>
          <FormControl fullWidth>
            <InputLabel shrink id="materialImovelSeguro" sx={styles.tipoimovellabel}>
              Selecione o material do imóvel:
            </InputLabel>

            <XSelect
              labelId="materialImovelSeguro"
              value={props.info?.materialImovel?.value}
              name="materialImovel"
              error={props.info?.materialImovel?.error}
              onChange={handleChange}
            >
              <MenuItem value={"Alvenaria"}>Alvenaria</MenuItem>
              <MenuItem value={"Outro"}>Outro</MenuItem>
            </XSelect>

            <CheckboxContentSeguro>
              <Checkbox
                pointer
                checked={props.info?.confirmaInfoVerdadeira?.value}
                name="confirmaInfoVerdadeira"
                onChange={handleChange}
                label={renderLabels("span", ACEITAR_TERMOS)}
              />
              Confirmo que as informações fornecidas são verdadeiras
            </CheckboxContentSeguro>
            <CheckboxContentSeguro>
              <Checkbox
                pointer
                checked={props.info?.aceitaTermosSeguro?.value}
                name="aceitaTermosSeguro"
                onChange={handleChange}
                label={renderLabels("span", ACEITAR_TERMOS)}
              />
              <SpanLinkTermosSeguro>Li e concordo com os </SpanLinkTermosSeguro>
              <LinkTermosSeguro
                href="https://www.tokiomarine.com.br/wp-content/uploads/2022/04/guia-servicos-assistencia-completo-residencial-042022v1.pdf"
                target="__blank"
              >
                Termos de contratação do Seguro Imobiliário Tokio Marine
              </LinkTermosSeguro>
            </CheckboxContentSeguro>
          </FormControl>
        </Grid>
      </>
    );
  };

  return <RenderSeguroInfos />;
}

export default Form;

/* {isValidArray(complementos) ? (
  complementos.map((item) => (
    <MenuItem key={item.name + item.condominioID} value={item}>
      {`${item.type} ${item.name}`}
    </MenuItem>
  ))
) : (
  <MenuItem value={0}>Sem unidade</MenuItem>
)}
 */
