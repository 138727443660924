//Step 1
export const PLANO_INDISPONIVEL = "Plano Indisponível";
export const SELECIONE_PLANO = "Selecione um plano para continuar";
export const PLANO_INDISPONIVEL_CEP =
  "O plano previamente selecionado não está disponível para esse CEP, para continuar selecione um dos planos disponíveis:";
export const PLANO_INDISPONIVEL_CIDADE =
  "Desculpe, não possuímos nenhum plano disponível na sua cidade";
export const CIDADE_NAO_ENCONTRADA = "Cidade não encontrada";
export const CEP_INVALIDO = "Digite um CEP válido";
export const RECAPTCHA_ERROR = "Houve um erro";

//Step 2
export const CLIQUE_FORMULARIO = "Clique aqui e acesse o formulário";
export const MATERIAL_SEGURO_INVALIDO =
  "O opcional Seguro só poderá ser adquirido para imóveis construídos em Alvenaria";

//Step 4
export const ACEITAR_TERMOS =
  "Declaro que li e aceito os termos de Contratação de Prestação de Serviço, Termo de Benefícios e Fidelização, Sumários de Contrato e, no caso de contratação do Seguro Residencial Tokio Marine, concordo com os termos de contratação.";
export const PRESTACAO_SERVICO = "Contrato de Prestação de Serviço.";
export const TERMO_FIDELIZACAO = "Termo de oferta de Benefício de Fidelização.";
export const SUMARIO_CONTRATO = "Sumários de Contrato.";

//Conclusão
export const ALERTA_EMAIL =
  "Dentro de instantes você receberá nosso e-mail de boas-vindas, contendo:";
export const APROVEITE_APLICATIVO =
  "Baixe nosso aplicativo e tenha acesso a todas as informações sobre seu plano.";
