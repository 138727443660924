import React, { Component } from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";

import {
  AsideContent,
  AsideLogo,
  PrevButton,
  CatchPhrase,
  AsideFooter,
  Copyright,
  AsideContainer,
  GreetContainer,
  ProductSettings,
} from "./styles";

class Aside extends Component {
  render() {
    const {
      logo,
      greet,
      product,
      greetText,
      footerLogo,
      havePrev,
      handlePrev,
      catchPhrase,
      companyName,
      contractType,
    } = this.props;
    const fullYear = new Date().getFullYear();

    return (
      <AsideContainer>
        {handlePrev && havePrev ? (
          <PrevButton onClick={handlePrev}>
            <HiOutlineArrowLeft size={24} />
          </PrevButton>
        ) : null}
        <AsideContent>
          <AsideLogo>
            <img src={logo} alt={companyName} />
          </AsideLogo>
          <CatchPhrase>
            <h1>{catchPhrase}</h1>
          </CatchPhrase>
          <ProductSettings>
            {product} / {contractType}
          </ProductSettings>
          <GreetContainer>
            <h2>{greet}</h2>
            {greetText}
          </GreetContainer>
        </AsideContent>
        <AsideFooter>
          <Copyright>
            <img src={footerLogo} alt={companyName} />
            <p>
              © {fullYear} {companyName}. Todos os direitos reservados.
            </p>
          </Copyright>
        </AsideFooter>
      </AsideContainer>
    );
  }
}

export default Aside;
