import styled from "styled-components";

export const MainContainer = styled.main`
  display: block;
  width: 100vw;
  flex-direction: column;
  @media (min-width: 800px) {
    padding-left: 40%;
    flex-direction: row;
    width: 100vw;
  }
`;

export const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 30px 20px;
  img {
    max-width: 80px;
    max-height: 80px;
    width: auto;
    height: auto;
    margin-bottom: 10px;
    filter: grayscale(1);
    opacity: 0.5;
  }
  p {
    margin-top: 10px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.aside.text};
  }
  @media (min-width: 800px) {
    display: none;
  }
`;
