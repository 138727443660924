import React, { useEffect, useRef } from "react";

import { useField } from "@unform/core";
import XTextInput from "./styles";

export default function XTextField({ externalError, name, ...rest }) {
  const textFieldRef = useRef(null);

  const {
    fieldName,
    registerField,
    defaultValue,
    error: formError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textFieldRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <>
      <XTextInput
        inputRef={textFieldRef}
        name={name}
        defaultValue={defaultValue}
        error={formError || externalError}
        helperText={formError ? formError || externalError : ""}
        {...rest}
      />
    </>
  );
}
