import styled from "styled-components";

export const Tittle = styled.p`
  font-size: 25px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 18px;
`;

export const Description = styled.p`
  margin-bottom: 15px;
  color: #fff;
`;

export const Emphasis = styled.span`
  font-weight: bold;
  color: #fff;
`;
