import styled from "styled-components";

export const Container = styled.div`
  border: 2px solid #e0e0e0;
  margin: 10% auto 0% auto;
  padding: 30px;
  width: 80%;
  height: auto;
  border-radius: 5px;
  display: inline-table;

  @media (max-width: 1200px) {
    height: auto;
    width: 80%;

    #img {
      display: none;
    }
  }

  @media (max-width: 800px) {
    #img {
      display: flex;
    }
  }
`;

export const Tittle = styled.div`
  color: #ff6f00;
  font-size: 32px;
  font-weight: bold;
`;

export const Divisor = styled.div`
  margin-top: 10px;
  border: 2px solid #ff6f00;
  width: 10%;
`;

export const Agrupament = styled.div`
  display: flex;

  @media (max-width: 800px) {
    display: block;
  }
`;

export const Content = styled.div`
  color: #727272;
  margin-top: ${(props) => props.gutterTop || "20px"};
  margin-right: 0;
  /* width: 70%; */
  font-size: 18px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  height: 168px;
  width: 168px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 90px 0px 0px;
`;
