import React from "react";
import PropTypes from "prop-types";

import StyledStepper, { StyledStepperConnector, StyledStep, StyledStepLabel, StyledStepperContainer } from "./styles";

function Stepper({ steps, activeStep, filledcolor }) {
  return (
    <StyledStepperContainer>
      <StyledStepper
        filledcolor={filledcolor}
        activeStep={activeStep}
        alternativeLabel
        connector={<StyledStepperConnector filledcolor={filledcolor} />}
      >
        {steps.map(({ label }) => (
          <StyledStep key={label}>
            <StyledStepLabel>{label}</StyledStepLabel>
          </StyledStep>
        ))}
      </StyledStepper>
      {steps[activeStep].content}
    </StyledStepperContainer>
  );
}

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  filledcolor: PropTypes.string,
};

Stepper.defaultProps = {
  filledcolor: null,
};

export default Stepper;
