import { createTheme } from "@mui/material";
import logoLigga from "../assets/img/ligga.svg";

export const theme = createTheme({
  input: {
    WebkitBoxShadow: "0 0 0 30px #1a1a1a inset",
  },

  colors: {
    primary: {},
    aside: {
      contrast: "#ED8836",
      headline: "#ff5f1e",
      text: "#fff",
      border: "#000",
    },
    stepper: {
      filledcolor: "#ED8836",
      title: "#fff",
    },
  },
  spacing: {},
  typography: {},
  assets: {
    logo: logoLigga,
  },
});

export default theme;
